import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { IEvent, IShow } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants";

import { apiService } from "src/services/api.service";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Checkbox from "src/components/Checkbox/Checkbox";
import DatePick from "src/components/DatePick/DatePick";

import styles from "./ShowNameTab.module.scss";

interface IProps {
  event: IEvent,
  show?: IShow,
  showType: string,
  clubId: string | undefined,
  onChange(akcNum: string, showName: string, startDate: any, closeDate: any, useCloseDate: boolean, isTrailingShow: boolean, previousShow: IShow | undefined): void
}

enum showNameTabErrors {
  NOT_FOUND,
  NO_CLUB
}

const ShowNameTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const [akcNum, setAkcNum] = useState<string>("");
  const [showName, setShowName] = useState<IOption>({ value: "Select a Show Name", id: "Select a Show Name" });
  const [isTrailingShow, setIsTrailingShow] = useState<boolean>(false);
  const [previousShow, setPreviousShow] = useState<IOption>({ value: "Select a Previous Show", id: "Select a Previous Show" });
  const [actualPreviousShow, setActualPreviousShow] = useState<IShow>();
  const [eventShows, setEventShows] = useState<IShow[]>([]);
  const [useCloseDate, setUseCloseDate] = useState<boolean>(false);

  let sDate = new Date(Date.now());
  sDate.setMinutes(0);
  sDate.setHours(9);
  const [showDate, setShowDate] = useState<Date>(sDate);

  const cDate = new Date();
  cDate.setMinutes(0);
  const [closeDate, setCloseDate] = useState<Date>(cDate);

  const getShowNamesByType = (sanctioningClub: string, showType: string) => {
    if (sanctioningClub === Constants.sanctioning_club.AKC && (showType === Constants.show_type.fast_cat || showType === Constants.show_type.fetch)) {
      return [
        { id: Constants.show_name.trial_1, value: Constants.show_name.trial_1 },
        { id: Constants.show_name.trial_2, value: Constants.show_name.trial_2 },
        { id: Constants.show_name.trial_3, value: Constants.show_name.trial_3 },
        { id: Constants.show_name.trial_4, value: Constants.show_name.trial_4 }
      ];
    }

    switch (showType) {
      case Constants.show_type.conformation:
        if (sanctioningClub === Constants.sanctioning_club.AKC || sanctioningClub === Constants.sanctioning_club.ABI) {
          return [
            { id: Constants.show_name.show_1, value: Constants.show_name.show_1 },
            { id: Constants.show_name.show_2, value: Constants.show_name.show_2 },
            { id: Constants.show_name.show_3, value: Constants.show_name.show_3 },
            { id: Constants.show_name.show_4, value: Constants.show_name.show_4 }
          ];
        } else {
          return [
            { id: Constants.show_name.show_1, value: Constants.show_name.show_1 },
            { id: Constants.show_name.show_2, value: Constants.show_name.show_2 }
          ];
        }
    }

    return [
      { id: Constants.show_name.trial_1, value: Constants.show_name.trial_1 },
      { id: Constants.show_name.trial_2, value: Constants.show_name.trial_2 }
    ];
  }

  const isSecondShow = () => {
    return (
      showName.value === Constants.show_name.trial_2 || 
      showName.value === Constants.show_name.show_2 || 
      showName.value === Constants.show_name.trial_3 || 
      showName.value === Constants.show_name.show_3 || 
      showName.value === Constants.show_name.trial_4 || 
      showName.value === Constants.show_name.show_4
    );
  }

  const getShowName = (s: IShow) => {
    return s.show_type + " " + s.show_name + " (" + moment(s.show_date).format("MMM Do") + ")";
  }

  const findPreviousShow = (clubShows: IShow[], show: IShow) => {
    for (let i in clubShows) {
      let cshow:IShow = clubShows[i];

      if (show?.previous_show_name?.includes(cshow.show_name)) {
        if (show?.previous_show_name.includes(cshow.show_type)) {
          if (show?.previous_show_name.includes(moment(cshow.show_date).format("MMM Do"))) {
            return cshow;
          }
        }
      }
    }
  }

  const getClubEventShows = useCallback(() => {
    return new Promise((resolve) => {
      let query: any = {
        sort: [{
          attribute_name: "show_type",
          sort: "asc"
        }, {
          attribute_name: "show_date",
          sort: "asc"
        }]
      };
      
      if (props.clubId) {
        apiService.getClubEventShows(props.clubId, props.event._id, query).then((response: any[]) => {
          resolve(response);
        }).catch(() => {
          resolve(showNameTabErrors.NOT_FOUND);
        });
      } else {
        resolve(showNameTabErrors.NO_CLUB);
      }
    });
  }, [props.clubId, props.event._id]);

  useEffect(() => {
    if (!loaded) {
      const init = async() => {
        const hydrateShowNameTab = async() => {
          if (props.show) {
            setAkcNum(props.show?.event_number || "");
            setShowName({ id: props.show.show_name, value: props.show.show_name });
            setShowDate(moment(props.show.show_date).toDate());
            setUseCloseDate(props.show.use_registration_end_date);
            setCloseDate(props.show.registration_end_date ? moment(props.show.registration_end_date).toDate() : new Date());
            setIsTrailingShow(props.show.trailing_show === true ? props.show.trailing_show : false);
            setPreviousShow({ value: props.show.previous_show_name || "", id: props.show.previous_show_name || "" });
          }
        }

        const clubShows:any = await getClubEventShows();

        if (clubShows.length > 0) {
          setEventShows(clubShows);

          if (props.show && props.show.trailing_show) {
            setActualPreviousShow(findPreviousShow(clubShows, props.show));
          }
        }

        if (props.show) {
          hydrateShowNameTab();
        }
      }

      init();
      setLoaded(true);
    }
  }, [loaded, getClubEventShows, props.show]);

  let { onChange } = props;

  useEffect(() => {
    onChange(akcNum, showName.id, showDate, closeDate, useCloseDate, isTrailingShow, actualPreviousShow);
  }, [onChange, akcNum, showName, showDate, closeDate, useCloseDate, isTrailingShow, actualPreviousShow]);

  useEffect(() => {
    const getShowById = (id: string) => {
      for (let s in eventShows) {
        if (eventShows[s]._id === id) {
          return eventShows[s];
        }
      }

      return undefined;
    }

    let show:any = getShowById(previousShow.id);

    if (show) {
      setActualPreviousShow(show);
    }
  }, [previousShow, eventShows]);

  return (
    <div className={styles.ShowNameTab}>
      <div className="stepTitle">Show Name and Date</div>
      <p>Whats the name of your show, and when is it?</p>
      <>{props.event?.sanctioning_club === Constants.sanctioning_club.AKC && <Input required label="AKC Number" defaultValue={akcNum} id="akcNum" type="text" onChange={(e) => { e.stopPropagation(); setAkcNum(e.target.value); }} />}</>
      <Dropdown
        value={showName}
        onChange={(e: any, value: IOption) => {
          setShowName(value);

          if (!isSecondShow()) {
            setIsTrailingShow(false);
          }
        }}
        label="Show Name"
        options={getShowNamesByType(props.event.sanctioning_club, props.showType)} placeholder="Select a Show Name"
      />
      {isSecondShow()}
      <>{(isSecondShow() && eventShows.length > 0) && <div className="trailingShow">
        <Checkbox onChange={(e:any) => { setIsTrailingShow(!isTrailingShow); }} value={isTrailingShow} id="isTrailingShow" label="This Show Follows a Previous Show" />
        {isTrailingShow && <div className="selectTrailingShow">
          <Dropdown value={previousShow} onChange={(e: any, value: IOption) => { setPreviousShow(value); }} label="Previous Show" options={eventShows.map((s: IShow) => { return { value: getShowName(s), id: s._id } })} placeholder="Choose a Previous Show" />
        </div>}
      </div>}</>
      <>{!isTrailingShow && <DatePick className="startDateTime" label="Show Start Date & Time" onChange={setShowDate} value={showDate} showTimeSelect={true} />}</>
      <p>Entries will close at the Show Start Date & Time unless an Entry Close Date is specified. </p>
      <Checkbox onChange={(e:any) => { setUseCloseDate(!useCloseDate); }} value={useCloseDate} id="useCloseDate" label="Use an Entry Close Date" />
      <>{useCloseDate &&
        <div className="earlyBird">
          <p>What day should we close entries on?</p>
          <DatePick label="Entry Close Date" onChange={setCloseDate} value={closeDate} showTimeSelect={true} />
        </div>
      }</>
    </div>
  );
};

export default ShowNameTab;