// ---------------
export const Constants = {
  //
  auth: {
    access_jwt_strategy_name: "access",
    refresh_jwt_strategy_name: "refresh",
  },

  auth_type: {
    none: "none",
    password: "password",
  },

  cookies: {
    refresh_token: "x-eagerdog-refresh-token",
  },

  user_type: {
    owner: "owner",
    club_manager: "club_manager",
  },

  dog_coowner_type: {
    owner: "owner",
    junior: "junior",
    handler: "handler",
  },

  token_type: {
    password: "password",
    refresh: "refresh",
    registration: "registration",
    email: "email",
    password_reset: "password_reset",
  },

  notification_events: {
    email_verification_token: "email-verification-token",
    email_welcome: "email-welcome",
    email_new_registration_dog_owner: "email_new_registration_dog_owner",
    email_entry_form_dog_owner: "email_entry_form_dog_owner",
    email_new_registration_kennel_club: "email_new_registration_kennel_club",
    email_send: "email-send",

    email_password_reset: "email_password_reset",
    email_results: "email_results",

    email_new_subscription: "email_new_subscription",
    email_send_message: "email_send_message",
    email_new_arm_number_per_show_type: "email_new_arm_number_per_show_type",

    email_waitlist_canceled_dog_owner: "email_waitlist_canceled_dog_owner",
  },

  notification_transport_type: {
    email: "email",
    sms: "sms",
  },

  object_type: {
    club: "club",
    dog: "dog",
    event: "event",
  },

  registration_number_type: {
    ukc_registered: "D",
    ukc_temporary: "TL",
    ukc_performance: "PL",
    akc_registered: "AKC",
    akc_pal: "PAL/ILP NO",
    akc_foreign_reg: "Foreign Reg.",

    abi_ukc: "UKC",
    abi_akc: "AKC",
    abi_ckc: "CKC",
    abi_other: "Other",
  },

  object_permissions: {
    manage: "manage",
  },

  event_status: {
    draft: "draft",
    published: "published",
    in_progress: "in_progress",
    complete: "complete",
    archived: "archived",
  },

  transaction_status: {
    pending: "pending",
    success: "success",
    failed: "failed",
  },

  sanctioning_club: {
    UKC: "UKC",
    AKC: "AKC",
    ABI: "ABI",
    CKC: "CKC",
    other: "Other",
  },

  show_name: {
    trial_1: "Trial 1",
    trial_2: "Trial 2",
    trial_3: "Trial 3",
    trial_4: "Trial 4",
    show_1: "Show 1",
    show_2: "Show 2",
    show_3: "Show 3",
    show_4: "Show 4",
  },

  show_type: {
    conformation: "Conformation",
    nosework: "Nosework",
    //performance: "Performance",
    obedience: "Obedience",
    rally_obedience: "Rally Obedience",
    agility: "Agility",
    weight_pull: "Weight Pull",
    urban_rat_race: "Urban Rat Race",
    country_rat_race: "Country Rat Race",
    scentwork: "Scentwork",

    fast_cat: "Fast CAT",
    fetch: "Fetch",
    lure_coursing: "Lure Coursing",
    precision_coursing: "Precision Coursing",
    dock_jumping: "Dock Jumping",

    shed_dog: "Shed Dog",
  },

  show_run_time_status: {
    available: "available",
    pending: "pending",
    reserved: "reserved",
  },

  account_type: {
    custom: "custom",
  },

  dog_sex: {
    male: "Male",
    female: "Female",
  },

  nq_reason: {
    incorrect_call: "Incorrect Call by Handler",
    false_alert: "False Alert by Dog",
    timed_out: "Timed Out",
    exposed_hide: "Exposed Hide or Distraction Prior to Alert",
    miss: "Miss of Non-indication",
    too_many_faults: "Too Many Faults",
  },

  scentwork_nq_reason: {
    incorrect_alert_finish_call: "Incorrect Alert/Finish call",
    harsh_correction: "Harsh correction",
    significant_disruption_of_area: "Significant disruption of area",
    handler_unable_to_point_to_hide: "Handler unable to point to hide",
    max_time_exceeded: "Max time exceeded",
  },

  scentwork_excused_reason: {
    dog_eliminated_in_search_area: "Dog eliminated in search area",
    dog_overly_stressed: "Dog overly stressed",
    handler_request: "Handler Request",
    other: "Other",
  },

  scentwork_fault_type: {
    handler_error: "Handler Error",
    safety_concern: "Safety Concern",
    mild_disruption_of_area: "Mild Disruption of Area",
  },

  dog_registration_status: {
    pending: "pending",
    active: "active",
  },

  dog_class_section: {
    none: "None",
    nosework_a: "A",
    nosework_b: "B",
    on_leash: "On Leash",
    unleashed: "Unleashed",
  },

  dog_class_element: {
    nosework_container: "Container",
    nosework_exterior: "Exterior",
    nosework_interior: "Interior",
    nosework_vehicle: "Vehicle",
    nosework_handler_descrimination: "Handler Disc.",

    conformation: "Conformation",
    conformation_altered: "Altered Conformation",
    conformation_non_licensed: "Non-Licensed Classes",

    novice_puppy: "Novice Puppy",
    veteran: "Veteran",
    brood_bitch_stud_dog: "Brood Bitch/Stud Dog",
    brace: "Brace",
    total_dog: "Total Dog",
    other: "Other",

    dock_jumping_air: "Air",
    dock_jumping_vertical: "Vertical",
    dock_jumping_fetch_it: "Fetch It",
    //dock_jumping: "Dock Jumping",

    abi_best_of_breed: "Best of Breed",
    abi_bred_by_exibitor: "Bred by Exibitor",

    akc_sweepstakes: "Sweepstakes",
    akc_futurity: "Futurity",
    akc_sled_dog: "Sled Dog",
    akc_junior_showmanship: "JR. Showmanship", //TODO

    performance_rally_obedience: "Rally Obedience",
    performance_obedience: "Obedience",
    performance_agility: "Agility",
    performance_non_licensed_obedience: "Non-Licensed Obedience",
    performance_weight_pull: "Weight Pull",
    performance_urban_rat_race: "Urban Rat Race",
    performance_country_rat_race: "Country Rat Race",

    scentwork_container: "Container",
    scentwork_interior: "Interior",
    scentwork_exterior: "Exterior",
    scentwork_buried: "Buried",
    scentwork_handler_disc: "Handler Discrimination",
    scentwork_detective: "Detective",

    tubes: "Tubes",
    unobstructed: "Unobstructed",
    obstructed: "Obstructed",
    fantastic_five: "Fantastic Five",
    tempting_ten: "Tempting Ten",

    beginner_a: "Beginner A",
    beginner_b: "Beginner B",
    intermediate_a: "Intermediate A",
    intermediate_b: "Intermediate B",
    excellent: "Excellent",

    fast_cat: "Fast CAT",
    fetch: "Fetch",

    //UKC junior showmanship
    junior_showmanship: "Junior Showmanship",

    //UKC, AKC Lure Coursing
    lure_coursing: "Lure Coursing",

    // UKC
    precision_coursing: "Precision Coursing",
    pc_division: "PC Division",

    // UKC Shed Dog
    shed_dog: "Shed Dog",
    // working_shed_dog: "Working Shed Dog",
    // shed_dog_champion: "Champion",
    // shed_dog_youth: "Youth",
  },

  dog_class_element_level: {
    // nosework
    novice: "Novice",
    advanced: "Advanced",
    superior: "Superior",
    master: "Master",
    elite: "Elite",
    excellent: "Excellent",

    //Conformation (UKC)
    puppy: "Puppy (6 months-under 1 year)",
    junior: "Junior (1 year-under 2 years)",
    adult: "Adult (2 years & over)",
    open: "Open (6 months & over)",
    breader_handler: "Breeder & Handler (6 months & over shown by breeder)",
    champion: "Champion (CH)",
    grand_champion: "Grand Champion (GRCH)",

    // Conformation (UKC) - non-licensed classes
    novice_puppy: "Novice Puppy",
    veteran: "Veteran",
    brood_bitch_stud_dog: "Brood Bitch/Stud Dog",
    brace: "Brace",
    total_dog: "Total Dog",
    other: "Other",

    //Conformation (AKC) - regular classes
    akc_4_6_month_puppy: "4-6 Month Puppy",
    akc_6_9_month_puppy: "6-9 Month Puppy",
    akc_9_12_month_puppy: "9-12 Month Puppy",
    akc_12_18_month_puppy: "12-18 Month Puppy",
    //akc_12_18_month_puppy: "12-18 Month Puppy",
    //akc_novice_or_amateur_handler: "Novice or Amateur-Owner-Handler",
    akc_under_12_month_bred_by_exhibitor: "Under 12 Month Bred-by-Exhibitor",
    akc_adult_bred_by_exhibitor: "Adult Bred-by-Exhibitor",
    akc_amateur_owner_handler: "Amateur-Owner-Handler",
    akc_american_bred: "American Bred",
    akc_open: "Open",
    akc_best_of_breed: "Best of Breed",

    akc_veteran_8_10_years: "Veteran (8-10 years)",
    akc_veteran_10_12_years: "Veteran (10-12 years)",
    akc_veteran_13_plus_years: "Veteran (13+ years)",
    akc_veteran_8_plus_years: "Veteran (8+ years)",
    akc_veteran_7_plus_years: "Veteran (7+ years)",
    akc_veteran_6_8_years: "Veteran (6-8 years)",

    //Conformation (AKC) - non-regular classes
    //This will have to be changed to accomadate sublevels
    akc_sweepstakes: "Sweepstakes",
    akc_futurity: "Futurity",
    akc_sled_dog: "Sled Dog",

    // performance - agility
    beginner_a: "Beginner A",
    beginner_b: "Beginner B",
    intermediate_a: "Intermediate A",
    intermediate_b: "Intermediate B",
    //excellent: "Excellent", //already exists

    // Agility - Dynamic Classes
    novice_hurdles: "Novice Hurdles",
    elite_hurdles: "Elite Hurdles",
    novice_tunnels: "Novice Tunnels",
    elite_tunnels: "Elite Tunnels",
    three_of_a_kind: "3 of a Kind",

    // Agility - Division
    toy_10_inch_and_under: 'Toy (10" & Under)',
    one_over_10_to_14_inch: '1 (over 10" - 14")',
    two_over_14_to_20_inch: '2 (over 14" - 20")',
    three_over_20_inch: '3 (over 20")',

    // Agility - Jump Height
    minimum_height: "Minimum Height",
    standard_height: "Standard Height",

    // Agility - weight pull
    division_a: "Division A",
    division_b: "Division B",
    // already exists
    //veteran: "Veteran",

    // performance - rally obedience
    ro1_a: "RO1 A",
    ro1_b: "RO1 B",
    ro1_c: "RO1 C",

    ro2_a: "RO2 A",
    ro2_b: "RO2 B",

    ro3_a: "RO3 A",
    ro3_b: "RO3 B",

    rom_a: "ROM A",
    rom_b: "ROM B",

    rae: "RAE",
    raem: "RAEM",

    // performance - obedience
    pre_novice: "Pre-Novice", // UKC
    beginner_novice: "Beginner Novice", // UKC
    beginner_novice_a: "Beginner Novice A", // AKC
    beginner_novice_b: "Beginner Novice B", // AKC
    preferred_novice: "Preferred Novice", // AKC
    novice_a: "Novice A", // UKC, AKC
    novice_b: "Novice B", // UKC, AKC
    novice_c: "Novice C", // UKC
    advance_novice: "Advance Novice", // UKC
    graduate_novice: "Graduate Novice", // AKC
    open_a: "Open A", // UKC, AKC
    open_b: "Open B", // UKC, AKC
    advance_open: "Advance Open", // UKC
    preferred_open: "Preferred Open", // AKC
    graduate_open: "Graduate Open", // AKC
    utility_a: "Utility A", // UKC, AKC
    utility_b: "Utility B", // UKC, AKC
    preferred_utility: "Preferred Utility", // AKC
    wc_novice: "Wild Card Novice",
    wc_open: "Wild Card Open",
    wc_utility: "Wild Card Utility",
    udx: "UDX",

    // master: "Master",
    // elite: "Elite",
    //veteran: "Veteran",

    // performance - non-licensed obedience
    //brace: "Brace",pakc-
    pairs: "Pairs",
    precision_heeling: "Precision Heeling",
    team: "Team",
    versatility: "Versatility", // AKC performance as well
    //veteran: "Veteran",

    // ABI Conformation
    abi_baby_puppy: "Baby Puppy",
    abi_6_9_months: "6 - 9 Month",
    abi_9_12_months: "9 - 12 Month",
    abi_12_18_months: "12 - 18 Month",
    abi_bred_by_exibitor: "Bred by Exibitor",
    abi_open: "Open",
    abi_best_of_breed: "Best of Breed",
    abi_brace: "Brace",
    abi_veteran: "Veteran",
    abi_altered: "Altered",
    abi_jr_champion: "Jr. Champion",
    abi_champion: "Champion",
    abi_master_champion: "Master Champion",
    abi_grand_champion: "Grand Champion",
    abi_supreme_champion: "Supreme Champion",
    abi_ultimate_champion: "Ultimate Champion",

    // ABI performance - Country Rat Race
    cubs: "Cubs",
    scouts: "Scouts",
    huntsman: "Huntsman",
    huntsmaster: "Huntsmaster",
    field_master: "Field Master",

    // ABI performance - Urban Rat Race
    rookie: "Rookie",
    clever: "Clever",
    adventurer: "Adventurer",
    explorer: "Explorer",
    detective: "Detective",
    inspector: "Inspector",

    fantastic_five: "Fantastic Five",
    tempting_ten: "Tempting Ten",

    fast_cat: "Fast CAT",

    // AKC Fetch
    //novice: "Novice"
    intermediate: "Intermediate",
    //advanced: "Advanced",
    retriever: "Retriever",

    // AKC Rally Obedience
    //novice_a: "Novice A",
    //novice_b: "Novice B",
    //intermediate: "Intermediate",
    advanced_a: "Advanced A",
    advanced_b: "Advanced B",
    excellent_a: "Excellent A",
    excellent_b: "Excellent B",
    //excellent: "Excellent",
    //master: "Master",
    choice: "Choice",

    // AKC Scentwork
    scentwork_novice_a: "Novice A",
    scentwork_novice_b: "Novice B",
    scentwork_advanced: "Advanced",
    scentwork_excellent: "Excellent",
    scentwork_master: "Master",
    scentwork_detective: "Detective",

    // UKC junior showmanship
    pee_wee: "Pee Wee (2 years-under 4 years)",
    sub_junior: "Sub JR (4 years-under 6 years)",
    pre_junior: "Pre-Junior (6 years-under 8 years)",
    novice_junior: "Novice Junior (8 years-under 13 years)",
    open_junior: "Open Junior (8 years-under 13 years)",
    novice_senior: "Novice Senior (13 years-18 years)",
    open_senior: "Open Senior (13 years-18 years)",
    total_junior: "Total Junior",

    //AKC Lure Coursing
    //open: "Open",
    //veteran: "Veteran",
    special: "Special",
    junior_coursing: "Junior Coursing",
    qualified_coursing: "Qualified Coursing",

    // UKC Precision Coursing
    // novice: "Novice",
    // advanced: "Advanced",
    // superior: "Superior",
    // master: "Master",
    // elite: "Elite"
    dash: "Dash",
    steeplechase: "Steeplechase",

    // UKC Precision Coursing Non-Licensed classes
    fun_run: "Fun Run",
    puppy_dash: "Puppy Dash",

    // UKC Lure Coursing
    coursing_aptitude: "Coursing Aptitude",
    coursing_ability: "Coursing Ability",
    coursing_tested: "Coursing Tested",
    ct_bye_dog: "CT - Bye Dog",
    regular_stakes: "Regular Stakes",

    // UKC PC Division
    toy: 'T (Toy) 10" & Under',
    one: '1 over 10" - 14"',
    two: '2 over 14" - 20"',
    three: '3 over 20" - 26"',
    g: 'G (Giant) over 26"',

    // UKC Shed Dog
    working_shed_dog: "Working Shed Dog",
    shed_dog_champion: "Champion",
    shed_dog_youth: "Youth",

    dock_jumping_splash_1: "Splash 1",
    dock_jumping_splash_2: "Splash 2",
    dock_jumping_splash_3: "Splash 3",
    dock_jumping_splash_4: "Splash 4",
    dock_jumping_splash_5: "Splash 5",
    dock_jumping_splash_6: "Splash 6",
    dock_jumping_vertical: "Vertical",
    dock_jumping_fetch_it: "Fetch It",
  },

  jump_height_type: {
    standard: "Standard",
    minimum: "Minimum",
  },

  payment_method_type: {
    card: "card",
    cash: "cash",
    etransfer: "e-transfer",
    paypal: "paypal",
    cheque: "cheque",
    other: "other",
  },

  payment_card_type: {
    unknown: "unknown",
    visa: "visa",
    mastercard: "mastercard",
    amex: "amex",
  },

  registration_kind: {
    owner: "owner",
    club: "club",
  },

  discount_type: {
    amount: "amount",
    percentage: "percentage",
  },
  // events_per_year: {
  //   "0 - 5": "0 - 5",
  //   "6 - 10": "6 - 10",
  //   "10 - 20": "10 - 20",
  //   "20+": "20+",
  // },
  incentive_type: {
    min_number_of_show_types: "min_number_of_show_types",
    number_of_entries: "number_of_entries",
    number_of_entries_fee_percentage: "number_of_entries_fee_percentage",
    number_of_entries_per_event_number: "number_of_entries_per_event_number",
    junior_account: "junior_account",
    number_of_shows: "number_of_shows",
  },

  form_events: {
    show_judge_books: "show_judge_books",
    show_scoresheets: "show_scoresheets",
    show_weigh_in_forms: "show_weigh_in_forms",
    event_scoresheets: "event_scoresheets",
    dog_entry_forms: "dog_entry_forms",
    transaction_entry_forms: "transaction_entry_forms",
    event_entry_forms: "event_entry_forms",
    event_registration_list: "event_registration_list",
    event_scores_list: "event_scores_list",
    event_transaction_list: "event_transaction_list",
    event_counts: "event_counts",
    show_type_score_list: "show_type_score_list",
    show_checkin_list: "show_checkin_list",
    show_results: "show_results",
    armband_labels: "armband_labels",
    armband_labels_back: "armband_labels_back",
    result_ribbon_labels: "result_ribbon_labels",
    event_results_email: "event_results_email",
    show_trial_report: "show_trial_report",
    event_catalog: "event_catalog",
    event_judge_schedule_calculator: "event_judge_schedule_calculator",
    judging_schedule: "judging_schedule",
    event_registration_summary: "event_registration_summary",
  },

  form_build_status: {
    pending: "pending",
    generating: "generating",
    complete: "complete",
    error: "error",
  },

  score_status: {
    pending: "pending",
    complete: "complete",
  },

  waitlist_status: {
    waitlist: "waitlist",
    registered: "registered",
    canceled: "canceled",
    refunded: "refunded",
  },

  score_fault_type: {
    handler_error: "handler_error",
    safety_concern: "safety_concern",
    compromised_area: "compromised_area",
    incorrect_response: "incorrect_response",
    fringe_response: "fringe_response",
    aggressive_response: "aggressive_response",
  },

  score_result_type: {
    qualified: "qualified",
    not_qualified: "not_qualified",
    disqualified: "disqualified",
    excused: "excused",
    absent: "absent",
  },

  akc_fastcat_titles: {
    fcat: "FCAT",
    bcat: "BCAT",
    dcat: "DCAT",
  },

  running_order_dog_class_type: {
    dog_class: "dog_class",
    break: "break",
  },

  ring_names: {
    ring_1: "Ring 1",
    ring_2: "Ring 2",
    ring_3: "Ring 3",
    ring_4: "Ring 4",
  },

  running_order_times: {
    break: 60,
    AKC: {
      rally_obedience: {
        novice_a: 3,
        novice_b: 3,
        intermediate: 3,
        advanced_a: 3,
        advanced_b: 3,
        excellent_a: 3,
        excellent_b: 3,
        master: 3,
        choice: 3,
        pairs: 5,
        team: 15,
      },
      obedience: {
        pre_novice: 6.666666667,
        beginner_novice: 6,
        beginner_novice_a: 6,
        beginner_novice_b: 6,
        preferred_novice: 6.666666667,
        novice_a: 6.666666667,
        novice_b: 6.666666667,
        novice_c: 6.666666667,
        advance_novice: 6.666666667,
        graduate_novice: 6.666666667,
        open_a: 7.5,
        open_b: 7.5,
        advance_open: 7.5,
        preferred_open: 7.5,
        graduate_open: 8.571428571,
        utility_a: 8.571428571,
        utility_b: 8.571428571,
        preferred_utility: 8.571428571,
        wc_novice: 6.666666667,
        wc_open: 7.5,
        wc_utility: 8.571428571,
        udx: 6.666666667,
        versatility: 10.2,
        veteran: 6.666666667,
        brace: 6.666666667,
        team: 8.4,
      },
    },
  },

  deductions: {
    heel_on_leash: "Heel on Leash",
    figure_eight: "Figure Eight",
    sit_for_exam: "Sit for Exam",
    sit_stay: "Sit Stay",
    recall: "Recall",
    misc: "Miscellaneous Penalties",
    heel_on_leash_figure_eight: "Heel on Leash and Figure Eight",
    stand_for_exam: "Stand for Exam",
    heel_free: "Heel Free",
    stay_sit_or_down: "Stay Sit or Down",
    sit_stay_get_your_leash: "Sit Stay Get Your Leash",
    heel_free_figure_eight: "Heel Free and Figure Eight",
    figure_eight_heel_free: "Figure Eight and Heel Free",
    cmd_disc_stand_down_sit: "Command Disc. Stand Down Sit",
    cmd_disc_down_sit_stand: "Command Disc. Down Sit Stand",
    cmd_disc_down_stand_sit: "Command Disc. Down Stand Sit",
    cmd_disc_stand_sit_down: "Command Disc. Stand Sit Down",
    drop_on_recall: "Drop on Recall",
    retrieve_on_flat: "Retrieve on Flat",
    retrieve_over_high_jump: "Retrieve Over High Jump",
    broad_jump: "Broad Jump",
    stand_stay_get_your_leash: "Stand Stay Get Your Leash",
    group_sit_and_down: "Group Sit and Down",
    heel_free_figure_eight_off_leash: "Heel Free and Figure Eight Off Leash",
    dumbbell_recall: "Dumbbell Recall",
    dumbbell_recall_over_high_jump: "Dumbbell Recall Over High Jump",
    recall_over_broad_jump: "Recall Over Broad Jump",
    signal_exercise: "Signal Exercise",
    scent_disc: "Scent Discrimination",
    scent_disc_art_1: "Scent Discrimination Article 1",
    scent_disc_art_2: "Scent Discrimination Article 2",
    go_out: "Go Out",
    directed_jumping: "Directed Jumping",
    moving_stand_and_exam: "Moving Stand and Exam",
    directed_retrieve: "Directed Retrieve",
    minor_deduction: "Minor Deduction",
    minor_or_substantial_deduction: "Minor or Substantial Deduction",
    substantial_deduction: "Substantial Deduction",
    non_qualfying: "Non-Qualifying",
    greeting: "Greeting",
    honor: "Honor",
    heel_off_leash: "Heel off Leash",
    recall_over_high_jump: "Recall Over High Jump",
    serpentine_loop_three_cones: "Serpentine Loop 3 Cones",
    down_stay: "Down Stay",
    straight_recall: "Straight Recall",
    stand_sit_for_exam: "Stand or Sit for Exam",
    down_stay_walk_away: "Down Stay Walk Away",
    moving_down: "Moving Down",
    recall_over_jump: "Recall Over Jump",
    heel_off_leash_figure_eight: "Heel Off Leash and Figure Eight",
    socialized_heeling: "Socialized Heeling",
    heel_off_leash_with_moving_exercise: "Heel Off Leash with Moving Exercise",
    figure_eight_with_down_stay: "Figure Eight With Down Stay",
    retrieve_of_an_object: "Retrieve of an Object",
    scent_discrimination: "Scent Discrimination",
    send_away_to_box: "Send Away to Box",
    signal_heeling: "Signal and Heeling",
    scent_work: "Scent Work",
    option_1_turn_and_send: "Option 1 Turn and Send",
    option_2_sit: "Option 2 Sit",
    directed_marked_retrieve: "Directed Marked Retrieve",
    directed_signal_retrieve: "Directed Signal Retrieve",
    consecutive_recall: "Consecutive Recall",

    signal_heeling_with_moving_stand_for_exam:
      "Signal Heeling with Moving Stand for Exam",
    food_refusal: "Food Refusal",
    recall_with_stand_and_down: "Recall with Stand and Down",
    advanced_directed_retrieve_and_jumping:
      "Advanced Directed Retrieve and Jumping",
    unfamiliar_scent_discrimination: "Unfamiliar Scent Discrimination",

    seek_back: "Seek Back",
    positions_with_recall: "Positions With Recall",
    send_to_cone_and_return: "Send to Cone and Return",
    send_away_with_dumbbell_retrieve: "Send Away with Dumbbell Retrieve",
    handler_discrimination: "Handler Discrimination",

    halt_sit: "Halt – Sit",
    halt_sit_walk_around: "Halt – Sit – Walk Around",
    right_turn: "Right Turn",
    left_turn: "Left Turn",
    about_turn_right: "About Turn – Right",
    two_hundred_seventy_degree_right_turn: "270 Degree Right Turn",
    two_hundred_seventy_degree_left_turn: "270 Degree Left Turn",
    three_hundred_sixty_degree_right_turn: "360 Degree Right Turn",
    three_hundred_sixty_degree_left_turn: "360 Degree Left Turn",
    serpentine_weave: "Serpentine Weave",
    fast_pace: "Fast Pace",
    slow_pace: "Slow Pace",
    call_front_finish_right_forward: "Call Front – Finish Right – Forward",
    call_front_finish_left_forward: "Call Front – Finish Left – Forward",
    halt_sit_down: "Halt – Sit – Down",
    halt_sit_down_walk_around_dog: "Halt – Sit – Down – Walk Around Dog",
    halt_stand: "Halt – Stand",
    halt_stand_walk_around_dog: "Halt – Stand – Walk Around Dog",
    straight_figure_eight_no_distractions:
      "Straight Figure Eight No Distractions",
    u_turn: "U-Turn",
    spiral_left_no_distractions: "Spiral Left No Distractions",
    spiral_right_no_distractions: "Spiral Right No Distractions",

    halt_sit_down_recall: "Halt – Sit – Down – Recall",
    halt_down_walk_around_dog: "Halt – Down – Walk Around Dog",
    halt_sit_stand_walk_around_dog: "Halt – Sit – Stand – Walk Around Dog",
    halt_stand_walk_around_dog_recall:
      "Halt – Stand – Walk Around Dog – Recall",
    halt_sit_right_turn_forward: "Halt – Sit – Right Turn – Forward",
    halt_sit_left_turn_forward: "Halt – Sit – Left Turn – Forward",
    right_pivot: "Right Pivot",
    left_pivot: "Left Pivot",
    call_front_one_step_back_finish_right:
      "Call Front – 1 Step Back – Finish Right",
    send_over_jump_return_to_heel: "Send Over Jump – Return to Heel",
    serpentine_weave_distractions: "Serpentine Weave Distractions",
    spiral_left_distractions: "Spiral Left Distractions",
    spiral_right_distractions: "Spiral Right Distractions",

    halt_call_front_two_steps_back_forward:
      "Halt – Call Front – 2 Steps Back – Forward",
    halt_stand_leave_call_to_heel: "Halt – Stand – Leave – Call to Heel",
    moving_stand: "Moving Stand",
    stand_leave_turn_call_to_heel: "Stand – Leave – Turn – Call to Heel",
    halt_sit_right_turn_forward_sit: "Halt – Sit – Right Turn – Forward – Sit",
    halt_sit_left_turn_forward_sit: "Halt – Sit – Left Turn – Forward – Sit",
    send_over_jump_recall: "Send Over Jump – Recall",
    halt_ninety_degree_right_pivot_sit: "Halt – 90° Right Pivot – Sit",
    halt_ninety_degree_left_pivot_sit: "Halt – 90° Left Pivot – Sit",
    halt_one_hundred_eighty_degree_right_pivot_sit:
      "Halt – 180° Right Pivot – Sit",
    halt_one_hundred_eighty_degree_left_pivot_sit:
      "Halt – 180° Left Pivot – Sit",

    halt_call_front_three_steps_back_forward:
      "Halt – Call Front – 3 Steps Back – Forward",
    halt_stand_walk_around_dog_call_to_heel:
      "Halt – Stand – Walk Around Dog – Call to Heel",
    halt_moving_down_walk_around_dog: "Halt – Moving Down – Walk Around Dog",
    halt_moving_stand_walk_around_dog: "Halt – Moving Stand – Walk Around Dog",
    halt_stand_leave_turn_call_to_heel:
      "Halt – Stand – Leave – Turn – Call to Heel",
    halt_drop_on_recall: "Halt – Drop on Recall",
    halt_stand_stay_send_over_jump_recall:
      "Halt – Stand – Stay – Send Over Jump – Recall",
    moving_side_step_right: "Moving Side Step Right",
    halt_stand_pivot_right_forward: "Halt – Stand – Pivot Right – Forward",
    halt_stand_pivot_left_forward: "Halt – Stand – Pivot Left – Forward",
    backup_three_steps_while_heeling: "Backup 3 Steps While Heeling",
    halt_stand_back_three_steps_forward:
      "Halt – Stand – Back 3 Steps – Forward",
    moving_stand_stay_call_to_heel: "Moving Stand – Stay – Call to Heel",
    halt_down_walk_around_call_to_heel:
      "Halt – Down – Walk Around – Call to Heel",

    halt: "Halt",
    //halt_stand: "Halt – Stand",
    halt_down: "Halt – Down",
    halt_down_sit: "Halt – Down – Sit",
    halt_walk_around_dog: "Halt – Walk Around Dog",
    //halt_down_walk_around_dog: "Halt – Down – Walk Around Dog",
    //right_turn: "Right Turn",
    //left_turn: "Left Turn",
    //about_turn_right: "About Turn – Right (180°)",
    about_turn_right_left_turn: "About Turn Right – Left Turn",
    about_u_turn: "About 'U' Turn",
    about_u_turn_left_right_turn: "About 'U' Turn Left – Right Turn",
    turn_270_right: "270° Right Turn",
    turn_270_left: "270° Left Turn",
    turn_360_right: "360° Right Turn",
    turn_360_left: "360° Left Turn",
    halt_90_pivot_right_halt: "Halt – 90° Pivot Right – Halt",
    halt_90_pivot_right_forward: "Halt – 90° Pivot Right & Forward",
    call_dog_front_handler_returns: "Call Dog Front – Handler Returns",
    call_dog_front_dog_right_forward: "Call Dog Front – Dog Right & Forward",
    call_dog_front_dog_left_forward: "Call Dog Front – Dog Left & Forward",
    call_dog_front_finish_right_halt: "Call Dog Front – Finish Right – Halt",
    call_dog_front_finish_left_halt: "Call Dog Front – Finish Left – Halt",
    //slow_pace: "Slow Pace",
    //fast_pace: "Fast Pace",
    normal_pace: "Normal Pace",
    //moving_side_step_right: "Moving Side Step Right",
    halt_side_step_right_forward: "Halt – Side Step Right & Forward",
    spiral_right_dog_outside: "Spiral Right – Dog Outside",
    spiral_left_dog_inside: "Spiral Left – Dog Inside",
    serpentine_4_cones: "Serpentine – 4 Cones",
    serpentine_5_cones: "Serpentine – 5 Cones",
    serpentine_loop_4_cones: "Serpentine Loop – 4 Cones",
    serpentine_loop_5_cones: "Serpentine Loop – 5 Cones",
    curl_dog_inside_3_cones: "Curl – Dog Inside – 3 Cones",
    curl_dog_outside_3_cones: "Curl – Dog Outside – 3 Cones",
    ribbon_loop_right: "Ribbon Loop – Right",
    halt_sit_stand: "Halt – Sit – Stand",
    // halt_sit_down: "Halt – Sit – Down",
    halt_sit_down_sit: "Halt – Sit – Down – Sit",
    //halt_sit_walk_around: "Halt – Sit – Walk Around",
    halt_stand_walk_around: "Halt – Stand – Walk Around",
    //halt_down_walk_around_call_to_heel: "Halt – Down – Walk Around – Call to Heel",
    halt_down_stand: "Halt – Down – Stand",
    halt_down_stand_walk_around: "Halt – Down – Stand – Walk Around",
    side_step_left: "Side Step Left",
    side_step_right: "Side Step Right",
    halt_180_pivot_right_halt: "Halt – 180° Pivot Right – Halt",
    halt_180_pivot_left_halt: "Halt – 180° Pivot Left – Halt",
    back_up_3_steps: "Back Up 3 Steps",
    back_up_3_steps_halt: "Back Up 3 Steps – Halt",
    //drop_on_recall: "Drop on Recall",
    //recall_over_jump: "Recall Over Jump",
    retrieve_dumbbell: "Retrieve Dumbbell",
    send_to_cone: "Send to Cone",
    send_to_box: "Send to Box",
    honor_stay: "Honor Stay",
    off_leash_pivot_right: "Off-Leash Pivot Right",
    off_leash_pivot_left: "Off-Leash Pivot Left",
    figure_8_heeling: "Figure 8 Heeling",
    change_of_pace_sit: "Change of Pace – Sit",
    distance_control: "Distance Control",
    directed_jump: "Directed Jump",
    off_leash_heeling_patterns: "Off-Leash Heeling Patterns",
    //directed_retrieve: "Directed Retrieve",
    stay_with_distraction: "Stay with Distraction",
    handler_disc_choice: "Handler’s Discretionary Choice",

    halt_leave_dog_face_dog_call_front_return_left:
      "HALT - Leave Dog, Face Dog, Call to Front, Handler Returns Left",
    halt_leave_dog_face_dog_call_front_return_right:
      "HALT - Leave Dog, Face Dog, Call to Front, Handler Returns Right",
    halt_sit_walk_around_cone:
      "HALT - Sit, Handler Walks Around Cone, Back to Dog",
    halt_down_walk_around_cone:
      "HALT - Down, Handler Walks Around Cone, Back to Dog",
    halt_leave_face_dog_down_return_forward:
      "HALT - Leave & Face Dog, Down Dog, Return & Forward",
    offset_figure_8: "Offset Figure 8",
    call_front_1_2_3_steps_back_right_forward:
      "Call Dog Front - 1, 2 & 3 Steps Backward - Dog Right & Forward",
    call_front_1_2_3_steps_back_left_forward:
      "Call Dog Front - 1, 2 & 3 Steps Backward - Dog Left & Forward",
    double_pivot_90_left_halt: "Double Pivot 90° Left - HALT",
    double_pivot_90_right_halt: "Double Pivot 90° Right - HALT",
    triple_loop_left: "Triple Loop Left",
    triple_loop_right: "Triple Loop Right",
    halt_90_pivot_left_forward: "HALT - 90° Pivot Left & Forward",
    halt_90_pivot_left_halt: "HALT - 90° Pivot Left - HALT",
    halt_about_turn_right_forward: "HALT - About Turn Right & Forward",
    halt_about_u_turn_forward: "HALT - About 'U' Turn & Forward",
    halt_90_pivot_right_halt_90_pivot_right_forward:
      "HALT 90° Pivot Right - HALT - 90° Pivot Right & Forward",
    halt_90_pivot_left_halt_90_pivot_left_forward:
      "HALT 90° Pivot Left - HALT - 90° Pivot Left & Forward",
    moving_down_forward_from_down: "Moving Down - Forward From Down",
    halt_pivot_90_left_call_heel: "HALT, Pivot 90° Left, Call Dog To Heel",
    halt_pivot_90_right_call_heel: "HALT - Pivot 90° Right, Call Dog To Heel",
    halt_turn_180_right_call_heel_halt:
      "HALT - Turn 180° To Right, Call Dog Heel - HALT",

    //RO1
    //halt: "HALT",
    //halt_stand: "HALT - Stand",
    //halt_down: "HALT - Down",
    //halt_down_sit: "HALT - Down - Sit",
    //halt_walk_around_dog: "HALT - Walk Around Dog",
    //halt_down_walk_around_dog: "HALT - Down - Walk Around Dog",
    //right_turn: "Right Turn",
    //left_turn: "Left Turn",
    //about_turn_right: "About Turn - Right (180°)",
    //about_turn_right_left_turn: "About Turn Right - Left Turn",
    //about_u_turn: "About 'U' Turn",
    //about_u_turn_left_right_turn: "About 'U' Turn Left - Right Turn",
    //turn_270_right: "270° Right Turn",
    //turn_270_left: "270° Left Turn",
    //turn_360_right: "360° Right Turn",
    //turn_360_left: "360° Left Turn",
    //halt_90_pivot_right_halt: "HALT - 90° Pivot Right - HALT",
    //halt_90_pivot_right_forward: "HALT - 90° Pivot Right & Forward",
    //call_dog_front_handler_returns: "Call Dog Front - Handler Returns",
    //call_dog_front_dog_right_forward: "Call Dog Front - Dog Right & Forward",
    //call_dog_front_dog_left_forward: "Call Dog Front - Dog Left & Forward",
    //call_dog_front_finish_right_halt: "Call Dog Front - Finish Right - HALT",
    //call_dog_front_finish_left_halt: "Call Dog Front - Finish Left - HALT",
    //slow_pace: "Slow Pace",
    //fast_pace: "Fast Pace",
    //normal_pace: "Normal Pace",
    //moving_side_step_right: "Moving Side Step Right",
    //halt_side_step_right_forward: "HALT - Side Step Right & Forward",
    //spiral_right_dog_outside: "Spiral Right - Dog Outside",
    //spiral_left_dog_inside: "Spiral Left - Dog Inside",
    //serpentine_4_cones: "Serpentine 4 Cones",
    //serpentine_5_cones: "Serpentine 5 Cones",
    //serpentine_loop_4_cones: "Serpentine Loop 4 Cones",
    //serpentine_loop_5_cones: "Serpentine Loop 5 Cones",
    //curl_dog_inside_3_cones: "Curl - Dog Inside 3 Cones",
    //curl_dog_outside_3_cones: "Curl - Dog Outside 3 Cones",
    //ribbon_loop_right: "Ribbon Loop Right",
    ribbon_loop_left: "Ribbon Loop Left",
    figure_8_to_right: "Figure 8 To Right (Using Cones)",
    figure_8_to_left: "Figure 8 To Left (Using Cones)",
    halt_1_2_3_steps_forward: "HALT - 1, 2, 3 Steps Forward",
    shift_right: "Shift Right",
    shift_left: "Shift Left",
    right_turn_150: "Right Turn (150)",
    right_turn_151: "Right Turn (151)",
    left_turn_152: "Left Turn (152)",
    left_turn_153: "Left Turn (153)",
    about_turn_right_154: "About Turn Right (154)",
    about_turn_left_155: "About Turn Left (155)",
    turn_270_right_156: "270° Right Turn (156)",
    turn_270_left_157: "270° Left Turn (157)",
    start: "Start",
    finish: "Finish",
    enter: "Enter",
    exit: "Exit",

    //RO2
    halt_leave_face_dog_call_front_finish_right:
      "HALT, Leave-Face Dog, Call Dog Front - Finish Right",
    halt_leave_face_dog_call_front_finish_left:
      "HALT-Leave-Face Dog, Call Dog Front, Finish Left",
    //halt_sit_walk_around_cone: "HALT - Sit, Handler Walks Around Cone, Back To Dog",
    //halt_down_walk_around_cone: "HALT - Down, Handler Walks Around Cone, Back To Dog",
    //halt_leave_face_dog_down_return_forward: "HALT - Leave & Face Dog, Down Dog, Return & Forward",
    //offset_figure_8: "Offset Figure 8",
    //call_front_1_2_3_steps_back_right_forward: "Call Dog Front - 1, 2 & 3 Steps Backward - Dog Right & Forward",
    //call_front_1_2_3_steps_back_left_forward: "Call Dog Front - 1, 2 & 3 Steps Backward - Dog Left & Forward",
    //double_pivot_90_left_halt: "Double Pivot 90° Left - HALT",
    //double_pivot_90_right_halt: "Double Pivot 90° Right - HALT",
    //triple_loop_left: "Triple Loop Left",
    //triple_loop_right: "Triple Loop Right",
    //halt_90_pivot_left_forward: "HALT - 90° Pivot Left & Forward",
    //halt_90_pivot_left_halt: "HALT - 90° Pivot Left - HALT",
    //halt_about_turn_right_forward: "HALT - About Turn Right & Forward",
    //halt_about_u_turn_forward: "HALT - About 'U' Turn & Forward",
    //halt_90_pivot_right_halt_90_pivot_right_forward: "HALT 90° Pivot Right - HALT - 90° Pivot Right & Forward",
    //halt_90_pivot_left_halt_90_pivot_left_forward: "HALT 90° Pivot Left - HALT - 90° Pivot Left & Forward",
    //moving_down_forward_from_down: "Moving Down - Forward From Down",
    //halt_pivot_90_left_call_heel: "HALT, Pivot 90° Left, Call Dog To Heel",
    //halt_pivot_90_right_call_heel: "HALT - Pivot 90° Right, Call Dog To Heel",
    //halt_turn_180_right_call_heel_halt: "HALT - Turn 180° To Right, Call Dog Heel - HALT",
    halt_leave_dog_face_dog_call_front_handler_return_left:
      "HALT - Leave Dog, Face Dog, Call to Front, Handler Returns Left",
    halt_leave_dog_face_dog_call_front_handler_return_right:
      "HALT - Leave Dog, Face Dog, Call to Front, Handler Returns Right",

    //RO3
    //halt_180_pivot_right_halt: "HALT - 180° Pivot Right - HALT",
    //halt_180_pivot_left_halt: "HALT - 180° Pivot Left - HALT",
    halt_180_pivot_right_halt_90_pivot_left_halt:
      "HALT – 180° Pivot Right – HALT – 90° Pivot Left – HALT",
    halt_180_pivot_left_halt_90_pivot_right_halt:
      "HALT - 180° Pivot Left – HALT - 90° Pivot Right – HALT",
    moving_down_handler_forward_face_dog_call_dog_finish_right:
      "Moving Down – Handler Forward – Face Dog – Call Dog – Finish Right",
    moving_down_handler_forward_face_dog_call_dog_finish_left:
      "Moving Down – Handler Forward – Face Dog – Call Dog – Finish Left",
    halt_send_dog_around_front_back_to_heel:
      "HALT, Send Dog Around Front of Handler & Back to Heel, HALT",
    halt_fast_forward_from_sit: "HALT - Fast Forward from Sit",
    left_about_turn: "Left About Turn",
    halt_stand_sit: "HALT - Stand - Sit",
    halt_stand_down: "HALT - Stand - Down",
    //halt_stand_walk_around_dog: "HALT - Stand - Walk Around Dog",
    halt_stand_heel_backward_3_steps_halt:
      "HALT - Stand - Heel Backward 3 Steps - HALT",
    halt_handler_walks_to_cone_call_dog_to_heel:
      "HALT - Handler Walks to Cone, Call Dog to Heel",
    send_over_jump_handler_runs_by: "Send Over Jump - Handler Runs By",
    turn_left_90_double_step_back_forward:
      "Turn Left 90°, Double Step Back, Forward",
    turn_right_90_double_step_back_forward:
      "Turn Right 90°, Double Step Back, Forward",
    halt_leave_dog_face_dog_call_to_heel_right:
      "HALT - Leave Dog, Face Dog, Call to Heel Right",
    halt_leave_dog_face_dog_call_to_heel_left:
      "HALT - Leave Dog, Face Dog, Call to Heel Left",
    moving_stand_walk_around_cone_back_to_dog:
      "Moving Stand, Walk Around Cone, Back to Dog",
    moving_sit_walk_around_cone_back_to_dog:
      "Moving Sit, Walk Around Cone, Back to Dog",
    moving_down_walk_around_cone_back_to_dog:
      "Moving Down, Walk Around Cone, Back to Dog",
    moving_down_handler_forward_call_dog_to_heel:
      "Moving Down, Handler Forward, Call Dog to Heel",
    moving_sit_handler_forward_call_dog_to_heel:
      "Moving Sit, Handler Forward, Call Dog To Heel",
    moving_stand_handler_forward_call_dog_to_heel:
      "Moving Stand, Handler Forward, Call Dog To Heel",
    honor_sit: "Honor Sit (Time Limit 1 1/2 Minutes)",
    honor_down: "Honor Down (Time Limit 1 1/2 Minutes)",

    //ROM
    call_dog_front_90_pivot_right_finish_left:
      "Call Dog Front, 90° Pivot Right, Finish Left",
    call_dog_front_90_pivot_left_finish_right:
      "Call Dog Front, 90° Pivot Left, Finish Right",
    halt_leave_call_dog_over_broad_jump_front_finish_right:
      "HALT - Leave - Call Dog Over Broad Jump - Front - Finish Right",
    halt_leave_call_dog_over_broad_jump_front_finish_left:
      "HALT - Leave - Call Dog Over Broad Jump - Front - Finish Left",
    halt_90_pivot_right_halt_180_pivot_left_halt:
      "HALT - 90° Pivot Right - HALT - 180° Pivot Left - HALT",
    halt_90_pivot_left_halt_180_pivot_right_halt:
      "HALT - 90° Pivot Left - HALT - 180° Pivot Right - HALT",
    forward_reverse_3_steps_pivot_90_left_forward:
      "Forward, Reverse 3 Steps, Pivot 90° Left & Forward",
    forward_reverse_3_steps_pivot_90_right_forward:
      "Forward, Reverse 3 Steps, Pivot 90° Right & Forward",
    spiral_in_dog_outside_spiral_out_dog_inside:
      "Spiral In - Dog Outside | Spiral Out - Dog Inside",
    spiral_in_dog_inside_spiral_out_dog_outside:
      "Spiral In – Dog Inside – Spiral Out – Dog Outside",
    halt_side_step_right_halt_270_right_turn_forward:
      "HALT - Side Step Right - HALT 270° Right Turn & Forward",
    halt_side_step_right_halt_270_left_turn_forward:
      "HALT - Side Step Right - HALT - 270° Left Turn & Forward",
    moving_stand_walk_to_cone_halt_call_dog_to_heel:
      "Moving Stand - Walk to Cone - HALT - Call Dog to Heel",
    moving_sit_walk_to_cone_halt_call_dog_to_heel:
      "Moving Sit - Walk to Cone - HALT - Call Dog to Heel",
    moving_down_walk_to_cone_halt_call_dog_to_heel:
      "Moving Down - Walk to Cone - HALT - Call Dog to Heel",
    moving_stand_handler_forward_face_dog_down_sit_finish_right:
      "Moving Stand, Handler Forward, Face Dog, Dog Down, Dog Sit, Finish Right",
    moving_stand_handler_forward_face_dog_down_sit_finish_left:
      "Moving Stand, Handler Forward, Face Dog, Dog Down, Dog Sit, Finish Left",
    cloverleaf: "Cloverleaf",
    halt_send_dog_around_cone_dog_front_handler_returns_right:
      "HALT Send Dog Around Cone, Dog Front, Handler Returns Right",
    halt_send_dog_around_cone_front_finish_right_180_pivot_right_forward:
      "HALT - Send Dog Around Cone, Front, Finish Right - 180° Pivot Right - Forward",
    halt_send_dog_around_cone_front_finish_left_180_pivot_right_forward:
      "HALT - Send Dog Around Cone - Front - Finish Left, 180° Pivot Right - Forward",
    halt_send_dog_around_cone_return_to_heel_right_180_pivot_right_forward:
      "HALT - Send Dog Around Cone - Return to Heel Right - 180° Pivot Right - Forward",
    halt_send_dog_forward_around_cone_return_to_heel_left_180_pivot_right_forward:
      "HALT - Send Dog Forward Around Cone - Return to Heel Left - 180° Pivot Right - Forward",
    halt_leave_dog_call_dog_over_jump_dog_front_handler_returns_right:
      "HALT – Leave Dog – Call Dog Over Jump – Dog Front - Handler Returns Right",

    //AKC Obedience Versitility
    scent_discrimination_metal: "Scent Discrimination (Metal)",
    scent_discrimination_leather: "Scent Discrimination (Leather)",
  },

  ukc_deduction_reasons: {
    //
    handler_error: "Handler Error",
    disciplining_dog: "Disciplining Dog",
    unsportsmanlike_conduct: "Unsportsmanlike Conduct",
    dog_misbehaviour: "Dog Misbehaviour",
  },

  ukc_non_qualifying_reasons: {
    dog_shows_fear: "Dog Shows Fear",
    fouling_ring: "Fouling Ring",
  },

  none: "None",
};
