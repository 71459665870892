import React, { useState } from "react";
import { Constants } from "@eagerdog/constants";

import { INoseworkScore, IFastcatScore, IFetchScore, RatRaceScoreDto, ScentworkScoreDto, DockJumpingScoreDto, ShedDogScoreDto, PrecisionCoursingScoreDto, WeightPullScoreDto, IShow, IEventSummary } from "@eagerdog/interfaces";

import { apiService } from "src/services/api.service";

import { toast } from "src/components/Toast/ToastManager";
import Button from "src/components/Button/Button";

import NoseworkResults from "./NoseworkResults/NoseworkResults";
import FastCatResults from "./FastCatResults/FastCatResults";
import FetchResults from "./FetchResults/FetchResults";
import UrbanRatRaceResults from "./UrbanRatRaceResults/UrbanRatRaceResults";
import CountryRatRaceResults from "./CountryRatRaceResults/CountryRatRaceResults";
import RallyObedienceResults from "./RallyObedienceResults/RallyObedienceResults";
import ObedienceResults from "./ObedienceResults/ObedienceResults";
import ScentworkResults from "./ScentworkResults/ScentworkResults";
import DockJumpingResults from "./DockJumpingResults/DockJumpingResults";
import ShedDogResults from "./ShedDogResults/ShedDogResults";
import PrecisionCoursingResults from "./PrecisionCoursingResults/PrecisionCoursingResults";
import WeightPullResults from "./WeightPullResults/WeightPullResults";

import "./EnterResults.scss";

interface IProps {
  showType: string,
  event: IEventSummary,
  result: any,
  eventId: string,
  onSave(): void,
  onClose(): void
}

const EnterResults: React.FC<IProps> = (props) => {
  const [judgeApproval, setJudgeApproval] = useState<boolean>(false);
  const [results, setResults] = useState<any>();
  const [show, setShow] = useState<IShow>();

  const hasValidSearchTime = () => {
    if (props.showType === Constants.show_type.weight_pull) {
      return true;
      /*if (results.time.toString().length > 0 && results.time.toString() !== "NaN") {
        return true;
      } else {
        return false;
      }*/
    }

    if (results && results.result_type !== Constants.score_result_type.qualified) {
      return true;
    }

    if (props.showType === Constants.show_type.fast_cat) {
      if (results && results.time.toString().length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (results && (results.search_time || results.search_time === "") && results.search_time.split !== undefined) {
        let searchTimeSplit = results.search_time.split(':');

        if (searchTimeSplit.length === 3) {
          for (let s in searchTimeSplit) {
            if (searchTimeSplit[s].length !== 2) {
              // Incorrect Length
              return false;
            }

            let isNumber = parseInt(searchTimeSplit[s]);

            if (isNaN(isNumber)) {
              // Not a Number
              return false;
            }
          }
        }else {
          // Wrong Length
          return false;
        }
      } else if (results && (isNaN(results.search_time) || results.search_time === 0) && results.search_time && results.search_time.split === undefined) {
        let isNumber = parseInt(results.search_time);

        if (isNaN(isNumber) || (results.search_time === 0)) {
          return false;
        }
      } else if (results && results.time) {
        let isNumber = parseInt(results.time);

        if (isNaN(isNumber)) {
          return false;
        }
      } else {
        // Results or Search Time Don't Exist
        return true;
      }
    }

    return true;
  }

  const enterResults = () => {
    if (hasValidSearchTime()) {
      if (results && results.result_type !== "None") {
        if (results && ((results.result_type !== Constants.score_result_type.qualified && results.result_reason !== "None" && results.result_reason !== "") || results.result_type === Constants.score_result_type.qualified || results.result_type === Constants.score_result_type.absent)) {
          switch (props.showType) {
            case Constants.show_type.weight_pull:
              if (results.weight_class && results.weight_class !== "None") {
                apiService.enterWeightPullScore(props.eventId, props.result._id, results).then((response) => {
                  props.onSave();
                }).catch((error) => {
                  toast.show({
                    title: "Enter Results",
                    content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                    duration: 10000,
                    type: "fail"
                  });
                });
              } else {
                toast.show({
                  title: "Enter Results",
                  content: "Please select a Weight Class",
                  duration: 10000,
                  type: "fail"
                });
              }
            break;
            case Constants.show_type.shed_dog:
              apiService.enterShedDogScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.scentwork:
              apiService.enterScentworkScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.obedience:
              apiService.enterObedienceScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.rally_obedience:
              apiService.enterRallyObedienceScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.nosework:
              apiService.enterNoseworkScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.fast_cat:
              apiService.enterFastcatScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.fetch:
              if (judgeApproval) {
                // include judgeApproval
                const payload = {
                  ...results,
                  approved_by_judge: judgeApproval
                }

                apiService.enterFetchScore(props.eventId, props.result._id, payload).then((response) => {
                  props.onSave();
                }).catch((error) => {
                  toast.show({
                    title: "Enter Results",
                    content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                    duration: 10000,
                    type: "fail"
                  });
                });
              } else {
                toast.show({
                  title: "Enter Results",
                  content: "Fetch results must be approved by a judge",
                  duration: 10000,
                  type: "fail"
                });
              }
            break;
            case Constants.show_type.urban_rat_race:
              apiService.enterUrbanRatRaceScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.country_rat_race:
              apiService.enterCountryRatRaceScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.dock_jumping:
              apiService.enterDockJumpingScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
            case Constants.show_type.precision_coursing:
              apiService.enterPrecisionCoursingScore(props.eventId, props.result._id, results).then((response) => {
                props.onSave();
              }).catch((error) => {
                toast.show({
                  title: "Enter Results",
                  content: error.response.data.message ? error.response.data.message : "Something went wrong when saving results",
                  duration: 10000,
                  type: "fail"
                });
              });
            break;
          }
        } else {
          toast.show({
            title: "Enter Results",
            content: "Please enter a result reason",
            duration: 10000,
            type: "fail"
          });
        }
      } else {
        toast.show({
          title: "Enter Results",
          content: "Please enter a result",
          duration: 10000,
          type: "fail"
        });
      }
    } else {
      toast.show({
        title: "Enter Results",
        content: "Please enter the search time in the correct format (two digits for each input)",
        duration: 10000,
        type: "fail"
      });
    }

    if (show !== undefined) {
      apiService.updateShow(show.event_id, show._id, show);
    }
  }

  const renderResults = () => {
    switch(props.showType) {
      case Constants.show_type.nosework:
        return <NoseworkResults result={props.result} onChange={(newResults: INoseworkScore) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.fast_cat:
        return <FastCatResults result={props.result} onChange={(newResults: IFastcatScore) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.fetch:
        return <FetchResults result={props.result} onChange={(newResults: IFetchScore, newJudgeApproval: boolean) => {
          setJudgeApproval(newJudgeApproval);
          setResults(newResults);
        }} />;
      case Constants.show_type.urban_rat_race:
        return <UrbanRatRaceResults result={props.result} onChange={(newResults: RatRaceScoreDto) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.country_rat_race:
        return <CountryRatRaceResults result={props.result} onChange={(newResults: RatRaceScoreDto) => {
          setResults(newResults);
        }} />;
        case Constants.show_type.rally_obedience:
        return <RallyObedienceResults event={props.event} onExerciseChange={props.onSave} result={props.result} onChange={(newResults: any, show?: IShow) => {
          if (show) {
            setShow(show);
          }

          setResults(newResults);
        }} />;
      case Constants.show_type.obedience:
        return <ObedienceResults result={props.result} onChange={(newResults: any, show?: IShow) => {
          if (show) {
            setShow(show);
          }

          setResults(newResults);
        }} />;
      case Constants.show_type.scentwork:
        return <ScentworkResults result={props.result} onChange={(newResults: ScentworkScoreDto) => {
          setResults(newResults);
        }} />;
      case Constants.show_type.dock_jumping:
        return <DockJumpingResults result={props.result} onChange={(newResults: DockJumpingScoreDto) => {
          setResults(newResults);
        }} />
      case Constants.show_type.shed_dog:
        return <ShedDogResults result={props.result} onChange={(newResults: ShedDogScoreDto) => {
          setResults(newResults);
        }} />
      case Constants.show_type.precision_coursing:
        return <PrecisionCoursingResults result={props.result} onChange={(newResults: PrecisionCoursingScoreDto) => {
          setResults(newResults);
        }} />
      case Constants.show_type.weight_pull:
        return <WeightPullResults result={props.result} onChange={(newResults: WeightPullScoreDto) => {
          setResults(newResults);
        }} />
    }
  }

  return (
    <div className="EnterResults">
      <div className="title">
        <span>Enter Results</span>
        <span>{props.result.call_name}</span>
      </div>
      <form className="results-inner" onSubmit={(e) => { e.preventDefault(); enterResults(); }}>
        {renderResults()}
        <div className="actions">
          <Button type="button" onClick={() => { props.onClose(); }}>Cancel</Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </div>
  );
};

export default EnterResults;