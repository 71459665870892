import React, { useEffect, useState } from "react";
import { Constants } from "@eagerdog/constants";
import { WeightPullScoreDto } from "@eagerdog/interfaces";

import Input from "src/components/Input/Input";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import StopWatch, { convertTimeToSeconds, convertTimeToMinutes } from "../StopWatch/StopWatch";

import "./WeightPullResults.scss";

interface IProps {
  result: any,
  onChange(result: WeightPullScoreDto): void
}

const WeightPullResults: React.FC<IProps> = (props) => {
  /*
  //
  @IsOptional()
  weight_class?: string;

  @IsOptional()
  @IsNumber()
  dog_weight?: number; //lbs

  // results
  @IsOptional()
  @IsNumber()
  highest_weight?: number; //lbs

  @IsOptional()
  @IsNumber()
  percent_pulled?: number; // (highest_weight / dog_weight) * 100

  @IsOptional()
  @IsNumber()
  time?: number; //number of seconds, decimal
  */

  const weightClassOptions:IOption [] = [
    { id: "Class 1 (0 – 20 lbs)", value: "Class 1 (0 – 20 lbs)" },
    { id: "Class 2 (21 – 40 lbs)", value: "Class 2 (21 – 40 lbs)" },
    { id: "Class 3 (41 – 60 lbs)", value: "Class 3 (41 – 60 lbs)" },
    { id: "Class 4 (61 – 80 lbs)", value: "Class 4 (61 – 80 lbs)" },
    { id: "Class 5 (81 – 100 lbs)", value: "Class 5 (81 – 100 lbs)" },
    { id: "Class 6 (101 – 120 lbs)", value: "Class 6 (101 – 120 lbs)" },
    { id: "Class 7 (121 – 140 lbs)", value: "Class 7 (121 – 140 lbs)" },
    { id: "Class 8 (141 – 160 lbs)", value: "Class 8 (141 – 160 lbs)" },
    { id: "Class 9 (Over 160 lbs)", value: "Class 9 (Over 160 lbs)" }
  ];

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [weightClass, setWeightClass] = useState<IOption>(props.result.weight_pull?.weight_class ? { id: props.result.weight_pull?.weight_class, value: props.result.weight_pull?.weight_class } : { id: Constants.none, value: Constants.none });
  const [dogWeight, setDogWeight] = useState<string>(props.result.weight_pull?.dog_weight ? props.result.weight_pull?.dog_weight.toString() : "");
  const [highestWeight, setHighestWeight] = useState<string>(props.result.weight_pull?.highest_weight ? props.result.weight_pull?.highest_weight.toString() : "");
  const [pctPulled, setPctPulled] = useState<string>(props.result.weight_pull?.percent_pulled ? props.result.weight_pull?.percent_pulled.toString() : "");
  const [time, setTime] = useState<string>(props.result.weight_pull?.time ? convertTimeToMinutes(props.result.weight_pull?.time.toString()) : "");

  useEffect(() => {
    if (hasChanged) {
      let _payload: any = {
        weight_class: weightClass.id,
        dog_weight: Number(dogWeight) || 0,
        highest_weight: Number(highestWeight) || 0,
        percent_pulled: Number(pctPulled) || 0,
        time: convertTimeToSeconds(time),
      };

      props.onChange(_payload);

      setHasChanged(false);
    }
  }, [hasChanged, props, weightClass, dogWeight, highestWeight, pctPulled, time]);

  return (
    <div className="WeightPullResults">
      <div className="row"><StopWatch time={time} onChange={(value: string) => { setTime(value); setHasChanged(true); }} /></div>
      <div className="row"><Dropdown value={weightClass} onChange={(e: any, value :IOption) => { setWeightClass(value); setHasChanged(true); }} label="Weight Class" options={weightClassOptions} placeholder="" /></div>
      <div className="row"><Input label="Dog Weight (lbs)" id="dogWeight" type="number" min="0" required onChange={(e) => { e.stopPropagation(); setDogWeight(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={dogWeight} /></div>
      <div className="row"><Input label="Highest Weight (lbs)" id="highestWeight" type="number" min="0" onChange={(e) => { e.stopPropagation(); setHighestWeight(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={highestWeight} /></div>
      <div className="row"><Input label="Percent Pulled (%)" id="pctPulled" type="number" min="0" onChange={(e) => { e.stopPropagation(); setPctPulled(e.target.value); setHasChanged(true); }} placeholder="" defaultValue={pctPulled} /></div>
    </div>
  );
};

export default WeightPullResults;