import React, { useState, useEffect, useCallback } from "react";
import { Constants } from "@eagerdog/constants";
import { AxiosError } from "axios";
import moment from 'moment-timezone';
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { helperService } from "../../services/helper.service";
import { IEventSummary, IEventContact, IUser, IShow, IShowElement, IDog, EventDto, ShowDto, IEventUKCConformationCount } from "@eagerdog/interfaces";

import './Event.scss';

import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/email.svg";
import date from "../../assets/icons/date.svg";

import { apiService } from "../../services";

import { toast } from "../../components/Toast/ToastManager";
import { Modal, useModal } from "../../components/Modal/Modal";
import Table, { IHeader, IRowAction } from "../../components/Table/Table";
import HorizontalScroll from "../../components/HorizontalScroll/HorizontalScroll";

import ShowForm from "../../components/StepForm/ShowForm/ShowForm";
import EventForm from "../../components/StepForm/EventForm/EventForm";
import Congrats from "../../components/Congrats/Congrats";
import DogPortrait from "../../components/DogPortrait/DogPortrait";
import Button from "../../components/Button/Button";
import UserSearch from "../../components/UserSearch/UserSearch";
import { download } from "src/components/Download/DownloadManager";
import Confirm from "src/components/Confirm/Confirm";
import TabContent, { ITab, useTabContent } from "../../components/TabContent/TabContent";
import RibbonSkip from "src/components/RibbonSkip/RibbonSkip";
import Counts from "src/components/Counts/Counts";

import ManageShow from "./ManageShow/ManageShow";

interface IProps {
  eid?: string
}

interface IShowTable {
  id: string,
  showType: string,
  showName: JSX.Element,
  date: JSX.Element,
  judge: string,
  price: string,
  actions?: IRowAction[]
}

interface ICountTable {
  breed_group: string,
  breed: string,
  counts: string
}

const Event: React.FC<IProps> = (props) => {
  const user:IUser = helperService.getUser();
  const navigate = useNavigate();
  let { eid } = useParams();
  
  const { isShown, toggle } = useModal();
  const [loading, setLoading] = useState<boolean>(false);

  const [showMore, setShowMore] = useState<boolean>(false);

  const [event, setEvent] = useState<IEventSummary>();

  const [tableShows, setTableShows] = useState<IShowTable[]>([]);
  const [shows, setShows] = useState<IShow[]>();

  const [showTypes, setShowTypes] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [secretary, setSecretary] = useState<IEventContact>();
  const [chairperson, setChairperson] = useState<IEventContact>();

  const [modalContent, setModalContent] = useState<string>("");

  const [registeredDogs, setRegisteredDogs] = useState<IDog[]>([]);
  const [editShow, setEditShow] = useState<IShow>();

  const [showToArchive, setShowToArchive] = useState<IShow>();
  const { isShown: isDeleteShown, toggle: toggleDelete } = useModal();

  const [manageShow, setManageShow] = useState<IShow>();

  const [DLShow, setDLShow] = useState<string>();

  const [showCounts, setShowCounts] = useState<ICountTable[]>([]);

  const [tabs, setTabs] = useState<ITab[]>([
    { name: "Show Schedule", actions: [] }
  ]);

  const [scheduleHeaders, setScheduleHeaders] = useState<IHeader[]>([
    { label: "Show Type", id: "showType" },
    { label: "Show Type", id: "showName" },
    { label: "Date", id: "date" },
    { label: "Judge", id: "judge" },
    { label: "Price (USD)", id: "price" }
  ]);

  const getJudgeNames = (show: IShow) => {
    if (show.show_elements.length > 0 && show.show_elements[0].judge_name !== undefined && show.show_elements[0].judge_name.length > 0) {
      return show.show_elements.map((e: IShowElement) => { return e.judge_name; }).filter((j:string, p:number, a:string[]) => { return a.indexOf(j) === p; }).join(", ");
    } else {
      return "Not Available";
    }
  }

  const setShowActions = useCallback((show: IShowTable, event: IEventSummary | undefined, showInfo: IShow) => {
    let _show = JSON.parse(JSON.stringify(show));

    let downloadable:string[] = [
      Constants.show_type.nosework
    ];

    if (isAdmin && event !== undefined) {
      _show.actions = [
        {
          icon: "download",
          options: [
            {
              label: "Download Judges Book",
              onClick: () => {
                if (event) {
                  download.pushDownload({
                    type: "judge_books",
                    eventId: event._id,
                    showId: showInfo._id,
                  });
                }
              }
            }
          ]
        }, {
          icon: "more",
          options: [{
              label: "Edit Show",
              onClick: () => {
                setEditShow(showInfo);
                setModalContent("showForm");
              }
            }, {
              label: "Delete Show",
              onClick: () => {
                setShowToArchive(showInfo);
                setModalContent("archiveShowModal");
              }
            }, 
            {
              label: "Manage Show",
              onClick: () => {
                setManageShow(showInfo);
                setModalContent("manageShowModal");
              }
            }
          ]
        }
      ];

      if (showInfo.show_type === Constants.show_type.scentwork) {
        _show.actions[0].options = [
          {
            label: "Download Scoresheets",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "scoresheets",
                  eventId: event._id,
                  showId: showInfo._id,
                });
              }
            }
          }
        ];

        _show.actions[1].options.push({
          label: "Enter Results",
          onClick: () => {
            navigate("/e/" + (eid !== undefined ? eid : props.eid) + "/manage?show=" + _show.showName.props.children[0].props.children);
          }
        });
      }

      if (showInfo.show_type === Constants.show_type.weight_pull) {
        _show.actions[0].options.push({
          label: "Download Weigh-In Forms",
          onClick: () => {
            download.pushDownload({
              type: "weigh-in",
              eventId: event._id,
              showId: showInfo._id,
            });
          }
        });
      }

      if (showInfo.show_type === Constants.show_type.fast_cat) {
        _show.actions[0].options = [
          {
            label: "Download Scoresheets",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "akc_scoresheets",
                  eventId: event._id,
                  showId: showInfo._id,
                });
              }
            }
          },
          {
            label: "Download Results",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "results",
                  eventId: event._id,
                  showId: showInfo._id,
                });
              }
            }
          }
        ];
      }

      if (showInfo.show_type === Constants.show_type.fetch) {
        _show.actions[0].options = [
          {
            label: "Download Check-in List",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "checkin",
                  eventId: event._id,
                  showId: "*" //all shows
                });
              }
            }
          },
          {
            label: "Download Scoresheets",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "scoresheets",
                  eventId: event._id,
                  showId: showInfo._id,
                });
              }
            }
          },
          {
            label: "Download Results",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "results",
                  eventId: event._id,
                  showId: showInfo._id,
                });
              }
            }
          }
        ];
      }

      if (event && event.sanctioning_club === Constants.sanctioning_club.UKC  && (showInfo.show_type === Constants.show_type.obedience || showInfo.show_type === Constants.show_type.rally_obedience || showInfo.show_type === Constants.show_type.shed_dog)) {
          _show.actions[0].options.push({
            label: "Download Scoresheets",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "scoresheets",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });
      }

      if (event && event.sanctioning_club === Constants.sanctioning_club.UKC  && (showInfo.show_type === Constants.show_type.conformation)) {
        _show.actions[0].options.push({
          label: "Download Counts",
          onClick: () => {
            if (event) {
              download.pushDownload({
                type: "counts",
                eventId: event._id,
                showId:  showInfo._id 
              });
            }
          }
        });
        
        _show.actions[1].options.push({
          label: "Show Counts",
          onClick: () => {

            let tableCounts: ICountTable[] = [];

            if (event) {
              apiService.getUKCConformationCounts(event._id, showInfo._id).then((response : IEventUKCConformationCount[]) => {

                tableCounts = response.map((c: IEventUKCConformationCount) => {
                  return {
                    breed_group: c.breed_group,
                    breed: c.breed,
                    counts:
                      c.male_count + '-' + c.female_count + '-' + c.champion_count + '-' + c.grand_champion_count +
                      (c.veteran_count > 0 ? '   VET-' + c.veteran_count : '') +
                      (c.novice_puppy_count > 0 ? '   NP-' + c.novice_puppy_count : '') +
                      (c.brace_count > 0 ? '   BR-' + c.brace_count : '') +
                      (c.altered_count > 0 ? '   ALT-' + c.altered_count : '')
                  }
                });
    
                setShowCounts(tableCounts);
                
              }).catch((e) => {
                toast.show({
                  title: "Couldn't retrieve counts",
                  content: e.message,
                  duration: 10000,
                  type: "fail"
                });
              });

              setModalContent("showCounts");
            }

          }
        });
      }

      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC  && (showInfo.show_type === Constants.show_type.lure_coursing)) {
        _show.actions[0].options= [{
          label: "Download Scoresheets",
          onClick: () => {
            if (event) {
              download.pushDownload({
                type: "scoresheets",
                eventId: event._id,
                showId: showInfo._id
              });
            }
          }
        }];
      }

      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC  && (showInfo.show_type === Constants.show_type.obedience || showInfo.show_type === Constants.show_type.rally_obedience)) {
        _show.actions[0].options.push({
          label: "Download Check-in List",
          onClick: () => {
            if (event) {
              download.pushDownload({
                type: "checkin",
                eventId: event._id,
                showId: showInfo._id
              });
            }
          }
        });
      }

      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC  && (showInfo.show_type === Constants.show_type.rally_obedience)) {
        _show.actions[0].options.push({
          label: "Download Scoresheets",
          onClick: () => {
            if (event) {
              download.pushDownload({
                type: "scoresheets",
                eventId: event._id,
                showId: showInfo._id
              });
            }
          }
        });
      }

      let hasAKCTrialReport:string[] = [
        Constants.show_type.rally_obedience,
        Constants.show_type.obedience
      ];

      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC && hasAKCTrialReport.includes(showInfo.show_type)) {
        if (_show.actions[0].options) {
          _show.actions[0].options.push({
            label: "Download Trial Report",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "trial",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });
        }
      }

      let hasUKCTrialReport:string[] = [
        Constants.show_type.conformation,
        Constants.show_type.precision_coursing,
        Constants.show_type.obedience,
        Constants.show_type.rally_obedience
      ];

      if (event && event.sanctioning_club === Constants.sanctioning_club.UKC && hasUKCTrialReport.includes(showInfo.show_type)) {
        if (_show.actions[0].options) {
          _show.actions[0].options.push({
            label: "Download Trial Report",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "trial",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });
        }
      }

      if (downloadable.includes(showInfo.show_type)) {
        if (_show.actions[0].options) {
          _show.actions[0].options.push({
            label: "Download Trial Report",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "trial",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });

          _show.actions[0].options.push({
            label: "Download NWT Export",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "nwt",
                  eventId: event._id,
                  showType: showInfo.show_type,
                });
              }
            }
          });

          _show.actions[0].options.push({
            label: "Download Check-in List",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "checkin",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });

          _show.actions[0].options.push({
            label: "Download Scoresheets",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "scoresheets",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });

          _show.actions[0].options.push({
            label: "Download Ribbon Labels",
            onClick: () => {
              if (event) {
                setDLShow(showInfo._id);
                setModalContent("ribbonSkipModal");
              }
            }
          });

          _show.actions[0].options.push({
            label: "Download Results",
            onClick: () => {
              if (event) {
                download.pushDownload({
                  type: "results",
                  eventId: event._id,
                  showId: showInfo._id
                });
              }
            }
          });
        }

        if (_show.actions[1].options) {
          _show.actions[1].options.push({
            label: "Enter Results",
            onClick: () => {
              navigate("/e/" + (eid !== undefined ? eid : props.eid) + "/manage?show=" + _show.showName.props.children[0].props.children);
            }
          });
        }

        
      }
    }

    return _show;
  }, [eid, isAdmin, navigate, props.eid]);

  const formatShowPrices = (show: IShow, formatter: Intl.NumberFormat) => {
    const { format } = formatter;
    const earlyBirdActual = show.use_early_bird_fee &&
      moment(show.early_bird_end_date).isAfter(new Date(), 'minutes');

    if (show.use_same_fee && !earlyBirdActual) {
      return format(show.fee);
    }

    if (show.use_same_fee && earlyBirdActual) {
      return show.early_bird_fee ? format(show.early_bird_fee) + " (Early Bird)" : format(show.fee);
    }

    if (!show.use_same_fee) {
      const elementFees = show.show_elements.flatMap((showElement) => {
        return [showElement.fee, showElement.early_bird_fee];
      }).filter((fee) => typeof fee === "number" && isFinite(fee)) as number[];
      const minFee = Math.min(...elementFees);
      const maxFee = Math.max(...elementFees);

      return `${[format(minFee), format(maxFee)].join(' - ')}${earlyBirdActual ? ' (Early Bird)' : ''}`;
    }

    return '-';
  }

  const getEvent = useCallback(() => {
    let _eid:string|undefined = eid ? eid : props.eid;

    if (_eid) {
      apiService.getEventSummaryForHandle(_eid).then((response) => {
        setEvent(response);

        if (response.currency) {
          let _scheduleHeaders = JSON.parse(JSON.stringify(scheduleHeaders));

          for (let s in _scheduleHeaders) {
            if (_scheduleHeaders[s].id === "price") {
              _scheduleHeaders[s].label = "Price (" + response.currency?.toUpperCase() + ")";
              break;
            }
          }

          setScheduleHeaders(_scheduleHeaders);
        }

        for (let c in response.contacts) {
          if (response.contacts[c].contact_type === "secretary") {
            setSecretary(response.contacts[c]);
          }

          if (response.contacts[c].contact_type === "chairperson") {
            setChairperson(response.contacts[c]);
          }
        }

        const queryParameters = new URLSearchParams(window.location.search);
        const dIds = queryParameters.get("dids");

        if (dIds) {
          apiService.getUserDogs(user._id).then((dogs) => {
            let _dogs = dogs.filter((d: IDog) => dIds.split(",").includes(d._id));
            setRegisteredDogs(_dogs);

            //window.history.pushState("", "", "/e/" + _eid);
          });
        } else {
          if (user.user_type === "owner") {
            apiService.getRegisteredDogsByCurrentUser(response._id).then((dogsResponse) => {
              setRegisteredDogs(dogsResponse);
            });
          }
        }

        if (response.club_id && response._id) {
          apiService.getClubEventShows(response.club_id, response._id, {
            sort: [{
              attribute_name: "show_type",
              sort: "asc"
            }, {
              attribute_name: "show_date",
              sort: "asc"
            }]
          }).then((eventResponse: any[]) => {
            let _showTypes:string[] = [];

            if (eventResponse) {
              let _shows:IShowTable[] = [];

              for(let s in eventResponse) {
                if (!_showTypes.includes(eventResponse[s].show_type)) {
                  _showTypes.push(eventResponse[s].show_type);
                }

                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: event?.currency === "cad" ? "USD" : event?.currency?.toUpperCase() || "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });

                const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

                let _show:IShowTable = {
                  id: eventResponse[s]._id,
                  showType: eventResponse[s].show_type,
                  showName: <div className="showNameInner"><span>{eventResponse[s].show_type}</span><span>{eventResponse[s].show_name}</span></div>,
                  //date: eventResponse[s].trailing_show ? "Follows " + (eventResponse[s].previous_show_name || " Previous Show/Trail") : moment(eventResponse[s].show_date).tz(timeZoneString).format("MMM Do YYYY, h:mmA zz"),
                  date:  <div className="dateInner"><span>{eventResponse[s].trailing_show ? moment(eventResponse[s].show_date).tz(timeZoneString).format("dddd") : moment(eventResponse[s].show_date).tz(timeZoneString).format("dddd A")}</span><span>{eventResponse[s].trailing_show ? moment(eventResponse[s].show_date).tz(timeZoneString).format("MMM Do") + " - Follows " + (eventResponse[s].previous_show_name || " Previous Show/Trail")  : moment(eventResponse[s].show_date).tz(timeZoneString).format("MMM Do @ h:mm zz")}</span></div>,
                  judge: getJudgeNames(eventResponse[s]),
                  price: formatShowPrices(eventResponse[s], formatter)
                };

                _show = setShowActions(_show, event, eventResponse[s]);

                _shows.push(_show);
              }

              setShows(eventResponse);
              setTableShows(_shows);

              if (response.club.created_by === user._id) {
                setIsAdmin(true);

                
                setTabs([
                  { name: "Show Schedule", actions: [ { label: "Create a Show", onClick: () => { setEditShow(undefined); setModalContent("showForm"); } } ] }
                ]);
                

                const s = queryParameters.get("s");

                if (s) {
                  setModalContent("showForm");
                  window.history.pushState("", "", "/e/" + _eid);
                }
              }
            }

            setShowTypes(_showTypes);
          }).catch((e: AxiosError) => {
            toast.show({
              title: "Event Shows",
              content: "Unable to get event shows",
              errorDetails: e,
              duration: 10000,
              type: "fail"
            });
          }).finally(() => {
            setLoading(false);
          });
        }
      }).catch((e) => {
        if (e.response.status === 404) {
          navigate("/not-found");
        }
      });

      
    }
  }, [eid, props.eid, navigate, setIsAdmin, event, user._id, user.user_type, scheduleHeaders, setShowActions]);

  const getModalContent = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const behalf = queryParameters.get("behalf");
    const waitlist = queryParameters.get("waitlist");

    switch (modalContent) {
      case "manageShowModal":
        return <ManageShow event={event!} show={manageShow!} onUpdate={() => { getEvent(); }} />
      case "showCounts":
        return <Counts onClose={() => { toggle(); }} tableCounts={showCounts} />;
      case "eventForm":
        return <EventForm user={user} event={event} onFinish={() => {
          toggle();
          getEvent();

          toast.show({
            title: "Update Event",
            content: "You've successfully updated your event",
            duration: 10000,
            type: "success"
          });
        }} />;
      case "showForm":
        return <ShowForm
          clubId={event ? event.club_id : undefined}
          event={event ? event : undefined}
          currency={event?.currency ? event.currency : "USD"}
          onFinish={() => {
            toggle();
            getEvent();

            if (editShow) {
              toast.show({
                title: "Edit Show",
                content: "You've successfully edited a show",
                duration: 10000,
                type: "success"
              });
            } else {
              toast.show({
                title: "Create Show",
                content: "You've successfully created a show",
                duration: 10000,
                type: "success"
              });
            }

            if (editShow) {
              setEditShow(undefined);
            }
          }}
          show={editShow ? editShow : undefined} />;
      case "secretaryDetails":
        return <div className="detailsInner">
          <div className="title">Secretary</div>
          <div className="name">{secretary?.first_name} {secretary?.last_name}</div>
          <div className="contactInfo">
            <div className="contactItem">
              <span className="label">Phone</span>
              <div className="contactValue"><a href={"tel:" + secretary?.phone}>{secretary?.phone}</a></div>
            </div>
            <div className="contactItem">
              <span className="label">Email</span>
              <div className="contactValue"><a href={"mailto:" + secretary?.email}>{secretary?.email}</a></div>
            </div>
          </div>
        </div>;
      case "chairpersonDetails":
        return <div className="detailsInner">
          <div className="title">Chairperson</div>
          <div className="name">{chairperson?.first_name} {chairperson?.last_name}</div>
          <div className="contactInfo">
            <div className="contactItem">
              <span className="label">Phone</span>
              <div className="contactValue"><a href={"tel:" + chairperson?.phone}>{chairperson?.phone}</a></div>
            </div>
            <div className="contactItem">
              <span className="label">Email</span>
              <div className="contactValue"><a href={"mailto:" + chairperson?.email}>{chairperson?.email}</a></div>
            </div>
          </div>
        </div>;
      case "searchUsersModal":
        return <UserSearch event={event?.handle || ""} />;
      case "archiveShowModal":
        return <Confirm message={showToArchive !== undefined ? <><p>Are you sure you want to delete {showToArchive.show_type} {showToArchive.show_name}</p></> : <></>} onConfirm={handleArchiveShow} onCancel={ () => { setShowToArchive(undefined); toggle() }} />
      case "ribbonSkipModal":
        return <RibbonSkip onSubmit={(skipAmt: number) => {
          if (event && DLShow) {
            download.pushDownload({
              type: "ribbons",
              eventId: event._id,
              showId: DLShow,
              labelsToSkip: skipAmt
            });

            toggle();
            setModalContent("");
          }
        }} />;

      default:
        return <Congrats
          text={behalf === "true" ? "You've successfully registered the user for your event. A copy of the receipt has been sent to their email." : "You've successfully registered for the event. A receipt has been sent to your email." + (waitlist === "true" ? " We'll notify you when your status on the waitlist has been updated.": "")}
          urlText={"View Event"}
          action={() => { toggle(); window.history.pushState("", "", "/e/"+ eid); }}
          optionalAction={() => { behalf === "true" ? navigate("/e/"+ eid +"/manage") : waitlist === "true" ? navigate("/account?t=4") : navigate("/account?t=3"); }}
          optionalActionText={behalf === "true" ? "View Registrants" : waitlist === "true" ? "View My Waitlist" : "View Transactions"}
        />;
    }
  }

  const handleArchiveShow = () => {
    if (!showToArchive) {
      return;
    }

    apiService.updateShow(event!._id, showToArchive._id, { ...showToArchive as ShowDto, is_archived: true})
      .then(() => { getEvent(); })
      .catch((e: AxiosError) => {
        toast.show({
          title: "Archive Show",
          content: "Unable to archive show",
          errorDetails: e,
          duration: 10000,
          type: "fail"
        });
      });

      setShowToArchive(undefined);
      toggle()
  }

  const handleArchiveEvent = () => {
    apiService.updateEvent(event!._id, { ...event as EventDto, is_archived: true }).then(() => {
      toast.show({
        title: "Delete Event",
        content: "You've successfully archived your event",
        duration: 10000,
        type: "success"
      });

      navigate("/");
    }).catch(error => {
      toast.show({
        title: "Delete Event",
        content: "Unable to archive event",
        errorDetails: error,
        duration: 10000,
        type: "fail"
      });
    });
  }

  useEffect(() => {
    if (event === undefined && !loading) {
      setLoading(true);
      getEvent();

      const queryParameters = new URLSearchParams(window.location.search);
      const redirectStatus = queryParameters.get("redirect_status");

      if (redirectStatus === "succeeded") {
        setModalContent("congratsModal");
      }
    }
  }, [getEvent, event, loading]);

  const pModalContent = helperService.usePrevious(modalContent);

  useEffect(() => {
    if (pModalContent !== modalContent) {
      if (modalContent !== "") {
        toggle();
      } else {
        let requiresReload:string[] = [
          "manageShowModal"
        ];

        if (pModalContent && requiresReload.includes(pModalContent)) {
          getEvent();
        }
      }
    } else {
      if (!isShown) {
        setModalContent("");
      }
    }
  }, [isShown, modalContent, pModalContent, setModalContent, toggle, getEvent]);

  const pIsAdmin = helperService.usePrevious(isAdmin);

  useEffect(() => {
    if (pIsAdmin !== isAdmin && isAdmin === true) {
      setScheduleHeaders([...scheduleHeaders, { label: "Actions", id: "actions" }]);
      let _shows = JSON.parse(JSON.stringify(tableShows));

      for (let s in _shows) {
        if (shows) {
          _shows[s] = setShowActions(_shows[s], event, shows[parseInt(s)]);
        }
      }

      setTableShows(_shows);
    }
  }, [pIsAdmin, isAdmin, event, scheduleHeaders, tableShows, shows, eid, navigate, setShowActions]);

  let _eid:string|undefined = eid ? eid : props.eid;

  const { setTab, activeTab } = useTabContent();

  const renderSchedule = () => {
    return(<>
      <Table
        headers={scheduleHeaders}
        data={tableShows}
        emptyMsg={
          <div className="emptyTable">
            <span>This event doesn't have any shows, yet.</span>
          </div>
        }
        action={isAdmin ? { text: "Create a Show", onClick: () => { setModalContent("showForm"); } } : undefined}
        />
    </>)
  }

  const getAdminMenu = (sanctioning_club: string) => {
    switch(sanctioning_club) {
    case Constants.sanctioning_club.UKC:
      return (<ul className="actionOptions">
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "armband_labels",
            eventId: event!._id
          });
        }}>Download Armband Labels (Front)</li>
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "armband_labels_back",
            eventId: event!._id
          });
        }}>Download Armband Labels (Back)</li>
      </ul>);
    case Constants.sanctioning_club.AKC:
      return (<ul className="actionOptions">
        <li onClick={() => {
          setShowMore(false);

          apiService.generateArmNumbersPerShowType(event!._id).then((response) => {
            toast.show({
              title: "Generate Armband Numbers",
              content: "Armband Numbers generated successfully",
              duration: 10000,
              type: "success"
            });
          }).catch((e: AxiosError) => {
            toast.show({
              title: "Generate Armband Numbers",
              content: "Unable to generate armband numbers",
              errorDetails: e,
              duration: 10000,
              type: "fail"
            });
          });
        }}>Generate Armband Numbers</li>
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "armband_labels",
            eventId: event!._id
          });
        }}>Download Armband Labels</li>
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "catalog",
            eventId: event!._id
          });
        }}>Download Event Catalog</li>
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "judge-scheduling-calc",
            eventId: event!._id
          });
        }}>Download Judge Scheduling Calculator</li>
        <li onClick={() => {
          setShowMore(false);

          download.pushDownload({
            type: "judge_schedules",
            eventId: event!._id
          });
        }}>Download Judge Schedules</li>
      </ul>);
    }

    return (<ul></ul>);
  }

  return(
    <div className="Event">
        {event && <Helmet>
          <title>{event?.title} Event - {event?.club_name ?? event?.club.club_name}</title>
          <meta name="description" content={event?.title + " Event on " + moment(event?.start_date).format('dddd MMMM Do, yyyy') + "."} />
          <meta name="robots" content="index, follow" />
        </Helmet>}
        <div className="eventInner">
          <div className="breadcrumb"><div className="path"><Link to="/">Events</Link> /</div><div className="current">Event Overview</div></div>
          {!loading &&<div className="eventDetails">
            {isAdmin && <div className="admin">
              <div className="status">
                <span className={"statusLabel " + event?.status}>{event ? helperService.getEventStatus(event?.status) : ""}</span>
                {event?.publish_at && event?.status === "draft" && <span className="publishTime">Set to go live on {moment(event?.publish_at).format("MMMM Do, YYYY @ ha")}</span>}
                {event?.publish_at && event?.status === "published" && <span className="publishTime">Live on {moment(event?.publish_at).format("MMMM Do, YYYY @ ha")}</span>}
              </div>
              <div className="actions">
                <div className="btnWrap"><button onClick={() => { setModalContent("eventForm"); }} className="edit">Edit Event</button></div>
                <div className="btnWrap"><Link to={"/e/" + event?.handle + "/manage"} className="manage">Manage Event</Link></div>
                {event && (event.sanctioning_club === Constants.sanctioning_club.AKC || event.sanctioning_club === Constants.sanctioning_club.UKC) && <div className="btnWrap">
                  <button onClick={() => { setShowMore(!showMore); }} className="more">More</button>
                  {showMore && getAdminMenu(event.sanctioning_club)}
                </div>}
                <div className="btnWrap"><button onClick={() => { toggleDelete() }} className="delete">Delete Event</button></div>
              </div>
            </div>}
            <div className="title">
              <span className={registeredDogs.length === 0 ? "titleText noDogs": "titleText "}>{event?.title}<span className="sanctioningClub">{event?.sanctioning_club}</span></span>
              {registeredDogs.length > 0 && <div className="registeredDogs">
                <ul>
                  {registeredDogs.map((dog: IDog, index:number) => {
                    return(<li key={index}><DogPortrait showName={false} dog={dog} /></li>);
                  })}
                  <li className="tag">Registered</li>
                </ul>
              </div>}
              {!isAdmin && <div className="actions">
                {user.user_type !== "club_manager" && (shows && shows.length > 0) && event?.status === Constants.event_status.published && <Link to={"/e/" + _eid + "/register"}>{registeredDogs.length > 0 ? "Register Again" : "Register"}</Link>}
                {user.user_type !== "club_manager" && (shows && shows.length === 0) && <div className="disabled-register"><span>Register</span><div className="hint">This event doesn't have any shows to register to, yet.</div></div>}
                {user.user_type !== "club_manager" && (shows && shows.length > 0) && event?.status !== Constants.event_status.published && <div className="disabled-register"><span>Register</span><div className="hint">This event isn't live yet.</div></div>}
                {user.user_type === "club_manager" && <div className="disabled-register"><span>Register</span><div className="hint">You can't register to an event because you're logged in as a club manager.</div></div>}
              </div>}
              {isAdmin && <div className="actions">
                <Button onClick={() => { setModalContent("searchUsersModal"); }}>Register a User</Button>
              </div>}
            </div>
            <div className="subtitle"><span>{event?.club_name ?? event?.club.club_name}</span></div>
            <div className="infoWrap">
              <div className="mainInfo">
                <div className="date start">
                  <div className="imgWrap"><img src={date} alt="Event Date" /></div>
                  <div className="infoItem"><span className="smallLabel">Starts</span><span>{moment(event?.start_date).format('dddd MMMM Do, yyyy')}</span></div>
                </div>
                <div className="date end">
                  <div className="imgWrap"><img src={date} alt="Event Date" /></div>
                  <div className="infoItem"><span className="smallLabel">Ends</span><span>{moment(event?.end_date).format('dddd MMMM Do, yyyy')}</span></div>
                </div>
                <div className="phone">
                  <div className="imgWrap"><img src={phone} alt="Event Phone Contact" /></div>
                  <div className="infoItem"><span className="smallLabel">Phone Contact</span><a href={"tel:" + event?.club?.phone}>{event?.club?.phone}</a></div>
                </div>
                <div className="email">
                  <div className="imgWrap"><img src={email} alt="Event Email Contact" /></div>
                  <div className="infoItem"><span className="smallLabel">Email Contact</span><a href={"mailto:" + event?.club?.email}>{event?.club?.email}</a></div>
                </div>                
              </div>
              <div className="info">
                <div className="persons">
                  <div className="conditions">
                    <div className="label conditionsLabel"><span>Conditions</span></div>
                    <span className="value">{event?.conditions}</span>
                  </div>
                  <div className="secretary">
                    <div className="label secretaryLabel"><span>Secretary</span></div>
                    <span onClick={() => { setModalContent("secretaryDetails"); }} className="value">{secretary?.first_name} {secretary?.last_name}</span>
                  </div>
                  <div className="chairperson">
                    <div className="label chairpersonLabel"><span>Chairperson</span></div>
                    <span onClick={() => { setModalContent("chairpersonDetails"); }} className="value">{chairperson?.first_name} {chairperson?.last_name}</span>
                  </div>
                </div>
                <div className="location">
                  <div className="locationLines">
                    <div>{event?.address.line1}{event?.address.line2 && ", " + event?.address.line2}</div>
                    <div>{event?.address.city}, {event?.address.state}</div>
                    <div>{event?.address.country}, {event?.address.postal_code}</div>
                  </div>
                </div>
                <div className="showTypes">
                  <div className="label"><span>Show Types</span></div>
                  <HorizontalScroll>
                    {showTypes.map((showType:string, index:number) => {
                      return(<li key={index} className="show">{showType}</li>);
                    })}
                  </HorizontalScroll>
                </div>
              </div>
            </div>
          </div>}
          {loading && <div className="eventDetails skeleton">
            <div className="details big"></div>
            <div className="details half"></div>
            <div className="infoWrap">
              <div className="image">
                <div className="details image"></div>
              </div>
              <div className="info spacer">
                <div className="details"></div>
                <div className="details"></div>
                <div className="details"></div>
                <div className="details spacer half"></div>
                <div className="details half"></div>
                <div className="details half"></div>
                <div className="details spacer"></div>
              </div>
            </div>
          </div>}
          <TabContent setTab={setTab} activeTab={activeTab} tabs={tabs} loading={loading}>
            {renderSchedule()}
          </TabContent>
        </div>
        <Modal
          className={modalContent}
          isShown={isShown}
          hide={toggle}
          modalContent={getModalContent()}
        />
        <Modal className="Confirmation" isShown={isDeleteShown} hide={toggleDelete} modalContent={
          <Confirm 
            message={<><p>Are you sure you want to delete this item?</p></>}
            onConfirm={handleArchiveEvent} 
            onCancel={() => toggleDelete()} 
          />
      } />
    </div>
  );
}

export default Event;