import React, { useState, useEffect, useCallback } from "react";
import { IEventSummary/*, IShow*/ } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/constants";
import { AxiosError } from "axios";

import moment from 'moment-timezone';

import { apiService } from "src/services/api.service";
import { ShowTypes } from "src/components/StepForm/ShowForm/ShowTypeTab/ShowTypes.definition";

import { toast } from "src/components/Toast/ToastManager";
import { download } from "src/components/Download/DownloadManager";
import { Modal, useModal } from "src/components/Modal/Modal";
import ContextMenu, { IContextMenuOption } from "src/components/ContextMenu/ContextMenu";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import Confirm from "src/components/Confirm/Confirm";
import Checkbox from "src/components/Checkbox/Checkbox";

import type { ColDef } from "ag-grid-community";
import { themeQuartz, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { ActionsRenderer } from "./ActionsRenderer";
import { StatusRenderer } from "./StatusRenderer";
import { ResultRenderer } from "./ResultRenderer";

import styles from "./ResultsTab.module.scss";

interface IProps {
  event: IEventSummary
}

interface IRow {
  callName: string,
  result: any,
  breed: string,
  show_id: string;
  show: string,
  date: string,
  element: string,
  level: string,
  section: string,
  status: any,
  actions: any
}

const ResultsTab: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<"checkin" | "scoresheets" | "ribbons" | "results" | "judge_books" | "email" | undefined>();
  const [gridApi, setGridApi] = useState<GridApi<IRow>>();

  const [show, setShow] = useState<IOption>({ value: "None", id: "None" });
  const [showElement, setShowElement] = useState<IOption>({ value: "None", id: "None" });
  const [showLevel, setShowLevel] = useState<IOption>({ value: "None", id: "None" });
  const [showSection, setShowSection] = useState<IOption>({ value: "None", id: "None" });

  const [showOptions, setShowOptions] = useState<IOption[]>([]);
  const [elementOptions, setElementOptions] = useState<IOption[]>([]);
  const [levelOptions, setLevelOptions] = useState<IOption[]>([]);
  const sectionOptions:IOption[] = [
    { value: "None", id: "None" },
    { value: "A", id: "A" },
    { value: "B", id: "B" }
  ];

  const [labelsToSkip, setLabelsToSkip] = useState<string>("0");

  const [isBulkEmailing, setIsBulkEmailing] = useState<boolean>(false);

  const { isShown, toggle } = useModal();

  const menuActions:IContextMenuOption[] = [
    {
      label: "Download Check-in List", 
      onClick: () => {
        downloadForm("checkin"); // showElement, showLevel, showSection
      }
    }, {
      label: "Download Scoresheets", 
      onClick: () => {
        downloadForm("scoresheets"); // showElement, showLevel, showSection
      }
    }, {
      label: "Download Ribbon Labels", 
      onClick: () => {
        setModalContent("ribbons"); toggle(); // labelsToSkip, showElement, showLevel, showSection
      }
    }, {
      label: "Download Results", 
      onClick: () => {
        downloadForm("results"); // showElement, showLevel, showSection
      }
    }, {
      label: "Download Judges Books", 
      onClick: () => {
        downloadForm("judge_books"); // showElement, showLevel, showSection
      }
    }, {
      label: "Bulk Email Results", 
      onClick: () => {
        setModalContent("email"); toggle();
      }
    }
  ];

  const theme = themeQuartz.withParams({
    fontFamily: "Roboto",
    headerFontFamily: "Roboto",
    cellFontFamily: "Roboto"
  });

  const downloadForm = (form: string | undefined) => {
    if (form) {
      let standardDownload:string[] = ["checkin", "scoresheets", "results", "judge_books", "ribbons"];

      if (standardDownload.includes(form)) {
        let _downloadOptions: any = {
          type: form,
          eventId: props.event._id,
          showId: show.id
        };

        //TODO Pass the actual show data so we can check the type
        if (show.value.includes("Fast CAT")) {
          _downloadOptions.type = "akc_scoresheets"
        }

        if (showElement.id !== "None") {
          _downloadOptions.showElement = showElement.id;
        }

        if (showLevel.id !== "None") {
          _downloadOptions.showLevel = showLevel.id;
        }

        if (showSection.id !== "None") {
          _downloadOptions.showSection = showSection.id;
        }

        if (form === "ribbons") {
          _downloadOptions.labelsToSkip = Number(labelsToSkip);
        }

        if (show.id !== "None") {
          download.pushDownload(_downloadOptions);
        } else {
          toast.show({
            title: "Download Results",
            content: "Please select a specific show from the dropdown",
            duration: 10000,
            type: "fail"
          });
        }
      }
    }
  }

  const bulkEmailResults = () => {
    if (!isBulkEmailing) {
      setIsBulkEmailing(true);

      if (show.id === "None") {
        apiService.requestResultsEmailForEvent(props.event._id, onlyQualified).then((response) => {
          toast.show({
            title: "Bulk Email Results",
            content: "You've successfully bulk emailed all event results",
            duration: 10000,
            type: "success"
          });  
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Bulk Email Results",
            content: "Unable to bulk email results",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        }).finally(() => {
          toggle();
          setIsBulkEmailing(false);
        });
      } else {
        apiService.requestResultsEmailForShow(props.event._id, show.id, onlyQualified).then((response) => {
          toast.show({  
            title: "Bulk Email Results",
            content: "You've successfully bulk emailed all show results",
            duration: 10000,
            type: "success"
          });  
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Bulk Email Results",
            content: "Unable to bulk email results",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        }).finally(() => {
          toggle();
          setIsBulkEmailing(false);
        });
      }
    }
  }

  const [onlyQualified, setOnlyQualified] = useState<boolean>(false);

  const handleOnlyQualified = (e: any) => {
    setOnlyQualified(!onlyQualified);
  }

  const getModalContent = () => {
    if (modalContent === "email") {
      return <Confirm
        message={show.id === "None" ?
        <>
          <p>You are about to bulk email <i>all</i> registrants to this event. <i>It is recommended you only do this once</i>. You should completely enter <i>all results to all shows</i> before doing this, have you completed entering all results?</p>
          <div>
            <Checkbox id={"onlyQualified"} onChange={handleOnlyQualified} value={onlyQualified} label={"Only email Qualified results"} />
          </div>
        </>
        :
        <>
          <p>You are about to bulk email <i>all</i> registrants to {show.value}. <i>It is recommended you only do this once</i>. You should completely enter <i>all results to {show.value}</i> before doing this, have you completed entering all results for {show.value}?</p>
          <div>
            <Checkbox id={"onlyQualified"} onChange={handleOnlyQualified} value={onlyQualified} label={"Only email Qualified results"} />
          </div>
        </>
      }
        onConfirm={() => {
          bulkEmailResults();
        }}
        onCancel={() => {
          toggle();
        }}
        buttonsDisabled={isBulkEmailing}
      />;
    } else {
      let title:string = "";

      switch (modalContent) {
        case "checkin":
          title = "Download Check-in List";
        break;
        case "scoresheets":
          title = "Download Scoresheets";
        break;
        case "ribbons":
          title = "Download Ribbon Labels";
        break;
        case "results":
          title = "Download Results";
        break;
        case "judge_books":
          title = "Download Judges Book";
        break;
      }

      return <form className={styles.downloadModalInner} onSubmit={(e) => { e.preventDefault(); downloadForm(modalContent); toggle(); }}>
        <div className={styles.title}>{title}</div>
        <div className={styles.modalInner}>
          {modalContent === "ribbons" && <Input type="text" onChange={(e:any) => { setLabelsToSkip(e.target.value); }} label="Labels to Skip" placeholder="How many labels do you want to skip?" defaultValue={labelsToSkip} />  }
        </div>
        <div className={styles.modalActions}>
          <Button type="submit">Download</Button>
        </div>
      </form>;
    }
  }

  const getResults = useCallback((id: string) => {
    let query:any = {
      sort: [{
        attribute_name: "show_type",
        sort: "asc"
      }, {
        attribute_name: "created_at",
        sort: "asc"
      }],
    };

    let hasResults:string[] = [
      Constants.show_type.nosework,
      Constants.show_type.fast_cat,
      Constants.show_type.fetch,
      Constants.show_type.urban_rat_race,
      Constants.show_type.country_rat_race,
      Constants.show_type.rally_obedience,
      Constants.show_type.obedience,
      Constants.show_type.scentwork,
      Constants.show_type.dock_jumping,
      Constants.show_type.shed_dog,
      Constants.show_type.precision_coursing,
      Constants.show_type.weight_pull
    ];

    let resultTypeLabels:any = {
      weighed: "weighed",
      complete: "complete",
      not_qualified: "nq",
      qualified: "qualified",
      disqualified: "disqualified",
      excused: "excused",
      absent: "absent",
      passed: "passed",
      failed: "failed",
      na: "unknown"
    }

    const getResultType = (result: any) => {
      if (result.nosework !== undefined) {
        return result.nosework.result_type;
      } else if (result.fetch !== undefined) {
        return result.fetch.passed === true ? "passed" : "failed";
      } else if (result.fastcat !== undefined) {
        return result.fastcat.result_type;
      } else if (result.urban_rat_race !== undefined) {
        return result.urban_rat_race.result_type;
      } else if (result.country_rat_race !== undefined) {
        return result.country_rat_race.result_type;
      } else if (result.rally_obedience !== undefined) {
        return result.rally_obedience.result_type;
      } else if (result.obedience !== undefined) {
        return result.obedience.result_type;
      } else if (result.scentwork !== undefined) {
        return result.scentwork.result_type;
      } else if (result.dock_jumping !== undefined) {
        return result.dock_jumping.result_type;
      } else if (result.shed_dog !== undefined) {
        return result.shed_dog.result_type;
      } else if (result.precision_coursing !== undefined) {
        return result.precision_coursing.result_type;
      } else if (result.weight_pull !== undefined) {
        if (result.weight_pull.time && result.weight_pull.time.toString().length > 0 && result.weight_pull.time.toString() !== "NaN") {
          return "complete";
        } else if (Number(result.weight_pull.dog_weight) > 0) {
          return "weighed";
        }
      }

      return "na";
    }

    apiService.getEventScores(id, query).then((response) => {
      let rows:IRow[] = [];

      for (let i in response) {
        let row:any = response[i];

        rows.push({
          callName: row.call_name + " "+ (row.arm_number !== undefined ? "(#" + row.arm_number + ")" : ""),
          result: resultTypeLabels[getResultType(row)],
          breed: row.breed,
          show_id: row.show_id,
          show: row.show_type + " " + row.show_name,
          date: moment(row.show_date).format("dddd") + " (" + moment(row.show_date).format("MM/DD/YY") + ")",
          element: row.dog_class.show_element,
          level: `${row.dog_class.level}${row.dog_class.group_class ? " (" + row.dog_class.group_class + ")" : ""}`,
          section: row.dog_class.section,
          status: row.status,
          actions: {
            result: row,
            event: props.event,
            showId: row.show_id,
            canEnterResults: hasResults.includes(row.show_type)
          }
        });
      }

      setRowData(rows);
    });
  }, [props.event]);

  let colDefs:ColDef<IRow>[] = [
    { field: "callName", minWidth: 120, filter: true },
    {
      field: "result",
      minWidth: 120,
      maxWidth: 120,
      filter: true,
      cellRenderer: ResultRenderer,
    },
    { field: "breed", minWidth: 150, filter: true },
    { field: "show_id", filter: true, hide: true },
    { field: "show", minWidth: 150, filter: true },
    { field: "date", minWidth: 150, filter: true },
    {
      field: "element",
      minWidth: 150,
      filter: true,
    },
    { field: "level", minWidth: 150, filter: true },
    { field: "section", minWidth: 80, filter: true },
    {
      field: "status",
      minWidth: 150,
      filter: true,
      cellRenderer: StatusRenderer,
    }, {
      headerName: "",
      field: "actions",
      maxWidth: 50,
      minWidth: 50,
      cellRenderer: ActionsRenderer,
      pinned: "right",
      cellRendererParams: {
        getResults: () => { getResults(props.event._id); }
      },
      resizable: false,
      cellStyle: {
        "overflow": "visible",
        "zIndex": "2"
      }
    }
  ];

  const [rowData, setRowData] = useState<IRow[]>([]);

  const defaultColDef: ColDef = {
    flex: 1
  };

  const onFirstDataRendered = (params:any) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const paramShow = queryParameters.get("show");

    if (paramShow) {
      params.api.setFilterModel({
          "show": {
              "values": [ paramShow ],
              "filterType": "set"
          }
      });
    }
  }

  const getShows = (clubId: string, eventId: string) => {
    apiService.getClubEventShows(clubId, eventId).then((response) => {
      // setShows(response);

      let options:IOption[] = [{ value: "None", id: "None" }];

      for (let i in response) {
        let show:any = response[i];

        options.push({
          //value: show.show_type + " " + show.show_name + " " + show._id,
          value: show.show_type + " " + show.show_name + " (" + moment(show.show_date).format("ddd, h:mm a") + ")",
          id: show._id,
          data: show
        });
      }

      setShowOptions(options);
    });
  }

  const isEmptyOptions = (options: IOption[]) => {
    if (options.length === 0) {
      return true;
    } else if (options.length === 1) {
      if (options[0].id === "None") {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  }

  const resetFilters = useCallback(() => {
    if (gridApi) {
      setShow({ id: "None", value: "None" });
      setShowElement({ id: "None", value: "None" });
      setShowLevel({ id: "None", value: "None" });
      gridApi.setFilterModel(null);
    }
  }, [gridApi]);

  useEffect(() => {
    setElementOptions([]);
    setLevelOptions([]);
    setShowSection({ id: "None", value: "None" });
    setShowElement({ id: "None", value: "None" });
    setShowLevel({ id: "None", value: "None" });

    if (show.data !== undefined && ShowTypes[props.event.sanctioning_club][show.data.show_type] !== undefined) {
      let _elementOptions:IOption[] = [{ id: "None", value: "None" }];
      let _levelOptions:IOption[] = [{ id: "None", value: "None" }];

      for (let elementName in ShowTypes[props.event.sanctioning_club][show.data.show_type]) {
        if (ShowTypes[props.event.sanctioning_club][show.data.show_type].length !== 1 && ShowTypes[props.event.sanctioning_club][show.data.show_type][elementName][0] !== elementName) {
          if (ShowTypes[props.event.sanctioning_club][show.data.show_type][elementName].length > 0) {
            _elementOptions.push({ value: elementName, id: elementName, data: show.data });
          } else {
            _levelOptions.push({ value: elementName, id: elementName, data: show.data });
          }
        }
      }

      setElementOptions(_elementOptions);

      if (_levelOptions.length > 1) {
        setLevelOptions(_levelOptions);
      }
    }
  }, [show, props.event.sanctioning_club]);

  useEffect(() => {
    if (showElement.id !== "None" && show.data !== undefined && ShowTypes[props.event.sanctioning_club][show.data.show_type] !== undefined) {
      setLevelOptions([]);
      setShowLevel({ id: "None", value: "None" });

      let _levelOptions:IOption[] = [{ id: "None", value: "None" }];

      for (let elementName in ShowTypes[props.event.sanctioning_club][show.data.show_type]) {
        let _element: any = ShowTypes[props.event.sanctioning_club][show.data.show_type][elementName];

        if (showElement.id === elementName) {
          for (let _level in _element) {
            _levelOptions.push({ value: _element[_level], id: _element[_level] });
          }
        }
      }

      setLevelOptions(_levelOptions);
    } else if (showElement.id === "None" && show.data !== undefined && ShowTypes[props.event.sanctioning_club][show.data.show_type] !== undefined) {
      let _levelOptions:IOption[] = [{ id: "None", value: "None" }];

      for (let elementName in ShowTypes[props.event.sanctioning_club][show.data.show_type]) {
        if (ShowTypes[props.event.sanctioning_club][show.data.show_type].length !== 1 && ShowTypes[props.event.sanctioning_club][show.data.show_type][elementName][0] !== elementName) {
          if (ShowTypes[props.event.sanctioning_club][show.data.show_type][elementName].length <= 0) {
            _levelOptions.push({ value: elementName, id: elementName, data: show.data });
          }
        }
      }

      setLevelOptions(_levelOptions);
    }
  }, [showElement, props.event.sanctioning_club, show.data]);

  useEffect(() => {
    if (!loaded) {
      getResults(props.event._id);
      getShows(props.event.club._id, props.event._id);

      setLoaded(true);
    }
  }, [loaded, getResults, props.event._id, props.event.club._id]);

  useEffect(() => {
    let filter: any = {};

    if (gridApi && show.id !== "None") {
      filter["show_id"] = {
        "values": [ show.id ],
        "filterType": "set",
      };
    }

    if (gridApi && showElement.id !== "None") {
      filter["element"] = {
        "values": [ showElement.value ],
        "filterType": "set"
      };
    }

    if (gridApi && showLevel.id !== "None") {
      filter["level"] = {
        "values": [ showLevel.value ],
        "filterType": "set"
      };
    }

    if (gridApi && showSection.id !== "None") {
      filter["section"] = {
        "values": [ showSection.value ],
        "filterType": "set"
      };
    }

    if (gridApi && filter["show_id"] === undefined && show.id !== "None" && showElement.id !== "None" && showLevel.id !== "None") {
      resetFilters();
    } else if (gridApi) {
      gridApi.setFilterModel(filter);
    }
  }, [gridApi, show, showElement, showLevel, showSection, resetFilters]);

  return (
    <div className={styles.ResultsTab}>
      <div className={styles.actions}>
        <div className={styles.left}>
          <Dropdown className={styles.dropdown} value={show} onChange={(e: any, value :IOption) => { setShow(value); }} label="Show" options={showOptions} placeholder="Select a Show" />
          <Dropdown className={styles.dropdown} disabled={isEmptyOptions(elementOptions)} value={showElement} onChange={(e: any, value :IOption) => { setShowElement(value); }} label="Element" options={elementOptions} placeholder="Select an Element" />
          <Dropdown className={styles.dropdown} disabled={isEmptyOptions(levelOptions)} value={showLevel} onChange={(e: any, value :IOption) => { setShowLevel(value); }} label="Level" options={levelOptions} placeholder="Select a Level" />
          <Dropdown className={`${styles.dropdown} ${styles.section}`} value={showSection} onChange={(e: any, value :IOption) => { setShowSection(value); }} label="Section" options={sectionOptions} placeholder="Select a Section" />
          <Button className={styles.reset} onClick={() => { resetFilters(); }}>Reset</Button>
        </div>
        <div className={styles.right}>
          <ContextMenu
            label={"Actions"}
            options={menuActions}
          />
        </div>
      </div>
      <div className={styles.tableWrap}>
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          suppressRowTransform={true}
          columnDefs={colDefs}
          onFirstDataRendered={onFirstDataRendered}
          defaultColDef={defaultColDef}
          theme={theme}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      <Modal
        className="downloadModal"
        isShown={isShown}
        hide={() => { toggle(); }}
        modalContent={getModalContent()}
      />
    </div>
  );
};

export default ResultsTab;